<template>
    <div class="search" v-cloak>
        <div class="adminSection">
            <div v-if="isAdmin > 0" class="home"><router-link to="/add" title="Ajouter un film">Ajouter</router-link>
            </div>
            <div v-if="isAdmin > 0" class="home"><router-link to="/dashboard/server"
                    title="Voir les films désactivés">Administrer</router-link></div>
            <div class="home"><router-link to="/ask"
                    title="Faire une demande et suivre vos demandes">Demandes</router-link>
            </div>
            <div class="home"><router-link to="/maliste" title="Voir tous vos films programmés">Ma
                    liste</router-link></div>
            <div class="home"><router-link to="/series" title="Voir les séries">
                    Séries<span class="total" v-if="isAdmin > 0 && stats || stats">{{ totalSeries
                        }}</span></router-link></div>
            <div class="home"><router-link to="/movies" title="Voir les films">Films <span class="total"
                        v-if="isAdmin > 0 && stats || stats">{{
                totalFilms
            }}</span></router-link></div>
            <div class="ops">
                <button class="opsBtn" title="Accéder aux options"><i class="fa-solid fa-gear"></i></button>
                <div class="dropdown">
                    <span><router-link to="/settings" title="Accéder à ses paramètres">Paramètres</router-link></span>
                    <span><router-link to="/history" title="Accéder à votre historique">Historique</router-link></span>
                    <span><button :onclick="() => disconnect()" title="Se déconnecter">Déconnexion</button></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBarContainer',
    data() {
        return {
            totalFilms: undefined,
            totalSeries: undefined,
            isAdmin: false,
            link: "https://api.heavenflix.fr",
            stats: false,
        }
    },
    methods: {
        disconnect: async function disconnect() {
            //requête pour logout l'utilisateur
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/logout/' + localStorage.getItem('token'), requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
            })
            localStorage.clear();
            this.$router.push('/login');
        },
        getTotalSeries: async function getTotalSeries() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/maxSeries', requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                if (data[0].count == 0) return this.totalSeries = 'N/A'
                this.totalSeries = data[0].count;
            })
        },
        getTotalMovies: async function getTotalMovies() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/maxMovies', requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                if (data[0].count == 0) return this.totalFilms = 'N/A'
                this.totalFilms = data[0].count;
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            if (!localStorage.getItem('userId')) {
                setTimeout(() => {
                    this.disconnect();
                }, 3000);
            }
            await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.msg) return console.log(data.msg);
                    this.isAdmin = data[0].isAdmin;
                })
        },
        getUserPreference: async function getUserPreference() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getUser/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.stats = data[0].wantStats;
            })
        },
    },
    created: function () {
        this.admin();
        this.getTotalMovies();
        this.getTotalSeries();
        this.getUserPreference();
    },
}
</script>

<style scoped lang="scss">
$dark-color: #1b151a;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$new-red: #A30F25;
$idk: #0d0e12;

.search {
    width: 100%;
    max-height: 75px;
    display: flex;
    padding: 20px 0;
    justify-content: end;
    align-items: center;
    background: $idk;
    z-index: 5;

    .adminSection {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        font-family: 'Lato';
        font-display: swap;
        margin-right: 3vw;

        .opsBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 5px 10px;
            background: transparent;
            color: $light-color;
            font-weight: bold;
            border-radius: 5px;
            font-size: 14px;
            transition-duration: 0.5s;

            i {
                font-size: 25px;
            }

            &:hover {
                cursor: pointer;
                color: $dark-red;
                transition-duration: 0.5s;
            }
        }

        .ops {
            position: relative;
            display: inline-block;
            z-index: 7;

            &:hover .dropdown {
                display: block;
            }

            .dropdown {
                display: none;
                position: absolute;
                background-color: #f1f1f1;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                top: 100%;
                right: 5%;
                animation: appear 1s forwards;

                span {
                    color: black;
                    text-decoration: none;
                    display: block;
                    padding: 5px;

                    a {
                        padding: 5px;
                        font-size: 16px;
                        text-decoration: none;
                        color: black;
                        height: 100%;
                    }

                    button {
                        border: none;
                        background: transparent;
                        color: $dark-red;
                        padding: 5px;
                        height: 100%;
                        font-size: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
        }

        .home {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 5px 10px;
            background: transparent;
            font-weight: bold;
            border-radius: 5px;
            position: relative;

            .total {
                position: absolute;
                top: -6px;
                right: -5px;
                background: $dark-red;
                padding: 2px;
                border-radius: 5px;
                font-size: 20px;
                z-index: -1;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                text-decoration: none;
                font-size: 14px;
                z-index: 1;
                gap: 5px;
            }

            i {
                font-size: 22px;
                margin-right: 5px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .search {
        .adminSection {
            width: fit-content;
        }
    }
}

@media screen and (max-width: 769px) {
    .search {
        .adminSection {
            width: fit-content;
        }
    }
}

@media screen and (max-width: 426px) {
    .search {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .searchBarContainer {
            width: 85%;
        }

        .adminSection {
            width: fit-content;
        }
    }
}
</style>
