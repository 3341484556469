<template>
    <nav>
        <ul>
            <li :class="{ active: $route.path.endsWith('/add') }">
                <router-link to="/add" title="Ajouter un film">Film</router-link>
            </li>
            <li :class="{ active: $route.path.endsWith('/addSerie') }">
                <router-link to="/addSerie" title="Ajouter une série">Série</router-link>
            </li>
            <li :class="{ active: $route.path.endsWith('/addSerieEpisode') }">
                <router-link to="/addSerieEpisode" title="Ajouter un épisode à une série">Episode</router-link>
            </li>
        </ul>
    </nav>
</template>


<script>
export default {
    name: 'AddMenuNavigation'
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

nav {
    margin: 40px auto 0 auto;
    background-color: $soft-grey;
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    overflow: hidden;
    height: 50px;

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {

            a {
                color: #fff;
                text-decoration: none;
                font-size: 1.2rem;
                font-family: 'Montserrat';
                background: $soft-grey;
                padding: 15px 25px;
                transition-duration: 0.5s;

                &:hover {
                    background: $dark-red;
                    transition-duration: 0.5s;
                }
            }
        }

        .active {
            a {
                background: $dark-red;
            }
        }
    }
}
</style>
