<template>
  <div class="homePage">
    <NavbarTop />
    <div class="settingsWrapper">
      <div class="profil" v-for="item in user" v-bind:key="item.id">
        <a class="category">Profil</a>
        <div class="pass">
          <label class="labels" for="username">Changer de nom d'utilisateur :</label>
          <input id="username" name="username" type="text" placeholder="Sarah Conor" :value="item.name">
          <button :onclick="() => changeName()">Confirmer</button>
          <i class="info">Changez de nom d'utilisateur pour celui de votre choix. Vous allez être déconnecté(e), il sera
            nécessaire d'utiliser le nouveau nom à la connexion.</i>
        </div>
        <div class="pass">
          <label class="labels" for="password">Changer de mot de passe :</label>
          <input id="password" name="password" type="password" placeholder="Nouveau mot de passe">
          <button :onclick="() => changePassword()">Confirmer</button>
          <i class="info">Changez de mot de passe pour celui de votre choix. Vous allez être déconnecté(e), il sera
            nécessaire d'utiliser le nouveau nom à la connexion.</i>
        </div>
        <div class="pass">
          <label class="labels" for="password">Changer/ajouter votre adresse mail :</label>
          <input id="email" name="email" type="email" placeholder="Nouvelle adresse mail">
          <button :onclick="() => changeEmail()">Confirmer</button>
          <i class="info warning" v-if="!item.email">Vous n'avez pas d'adresse mail
            enregistrée,
            merci d'en renseigner une.</i>
          <i class="info">Changez d'adresse mail pour celle de votre choix. Celle-ci ne sert à qu'à la récupération de
            mot
            de passe. Aucune action supplémentaire requise.</i>
        </div>
        <a class="category">Préférences</a>
        <div class="cardLimit">
          <label class="labels" for="cardRange">Nombre de films à afficher :</label>
          <input id="limitRange" name="cardRange" type="range" :value="item.limitCards" min="5" step="5" max="250"
            oninput="this.nextElementSibling.value = this.value">
          <output class="output">{{ this.limit }}</output>
          <i class="info">Défini le nombre de films affichés avant de devoir en charger d'avantage. Plus le nombre est
            élevé, plus le site mettra de temps à charger.</i>
        </div>
        <div class="darkTheme">
          <div class="darkContainer">
            <input type="checkbox" id="darkCheck" name="darkMode" checked>
            <label class="labels" for="darkMode">Mode sombre</label>
          </div>
          <i class="info">Le mode sombre permet d'éviter l'éboulïssement dans un environnement sombre. En plein jour, il
            est préférable de le désactiver pour profiter d'une meilleure lisibilité.</i>
        </div>
        <div class="darkTheme">
          <div class="darkContainer">
            <input type="checkbox" id="statsCheck" name="darkMode" :checked="wantStats">
            <label class="labels" for="darkMode">Voir les statistiques</label>
          </div>
          <i class="info">Activer la visibilité des statistiques permet de voir le nombre de films & séries disponibles dans la barre de navigation.</i>
        </div>
        <div class="darkTheme">
          <div class="darkContainer">
            <input type="checkbox" id="carrouselCheck" name="darkMode" :checked="wantCarrousel">
            <label class="labels" for="darkMode">Collections en carrousel</label>
          </div>
          <i class="info">Activer les collections en carrousel permet d'afficher les collections via des images dans un
            carrousel défilant. Le désactiver affichera les collections en menu déroulant habituel.</i>
        </div>
        <div class="darkTheme">
          <p class="title">Choisir le mode d'affichage des films</p>
          <div class="cardsContainer">
            <div :class="['cardPoster', wantWideCards ? '' : 'cardActive']" @click="setWantWideCards(false)">
              <img :src="exampleMovie.posterURL" :alt="'Poster du film ' + exampleMovie.name">
              <div class="details">
                <div class="nameCat">
                  <h4>{{ exampleMovie.name }}</h4>
                  <p>{{ exampleMovie.cat }}</p>
                </div>
                <div class="selection"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></div>
              </div>
            </div>
            <div :class="['cardWide', wantWideCards ? 'cardActive' : '']" @click="setWantWideCards(true)">
              <img :src="exampleMovie.backgroundURL" :alt="'Poster du film ' + exampleMovie.name">
              <div class="details">

                <div class="nameCat">
                  <h4>{{ exampleMovie.name }}</h4>
                  <p>{{ exampleMovie.cat }}</p>
                </div>
                <div class="selection"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></div>
              </div>
            </div>
          </div>
          <i>Choisissez le design que vous désirez pour la selection des films. Ils seront affichés sous ce format dans
            les menus principaux et dans
            la section "Ma liste".</i>
        </div>
        <a class="category">Préférences du lecteur</a>
        <div class="darkTheme preferences" v-if="user_preferences">
          <div class="darkContainer">
            <input type="checkbox" id="autoplayCheck" name="autoplay" :checked="user_preferences.autoplay">
            <label class="labels" for="autoplay">Activer/désactiver la lecture automatique</label>
          </div>
          <i class="info">Lorsque la lecture automatique est activée, la lecture se lancera automatiquement quand vous
            arrivez sur la page du lecteur.</i>
          <div class="darkContainer">
            <input type="checkbox" id="fullscreenCheck" name="fullscreen" :checked="user_preferences.fullscreen_auto">
            <label class="labels" for="fullscreen">Activer/désactiver le plein écran automatique</label>
          </div>
          <i class="info">Lorsque le plein écran automatique est activé, le lecteur se mettra en plein écran
            automatiquement lors du lancement de la lecture et se retirera au générique.</i>
          <div class="darkContainer">
            <input type="checkbox" id="saveWatchtimeCheck" name="saveWatchtime"
              :checked="user_preferences.save_watchtime">
            <label class="labels" for="saveWatchtime">Activer/désactiver la sauvegarde de la progression</label>
          </div>
          <i class="info">Lorsque la sauvegarde de la progression est activée, la progression de la lecture sera
            sauvegardée et vous pourrez reprendre la lecture là où vous l'avez laissée.</i>
          <div class="darkContainer">
            <label class="labels" for="playbackSpeed">Vitesse de lecture (x1 par défaut) :</label>
            <input id="playbackSpeed" name="playbackSpeed" type="range" :value="user_preferences.playback_speed"
              min="0.0" step="0.1" max="2.0" oninput="this.nextElementSibling.value = 'x' + this.value">
            <output class="output">{{ 'x' + user_preferences.playback_speed }}</output>
          </div>
          <i class="info">Défini la vitesse de lecture par défaut. Vous pourrez toujours la modifier dans le
            lecteur.</i>
          <div class="darkContainer">
            <label class="labels" for="volume">Préférence du volume :</label>
            <input id="volume" name="volume" type="range" :value="user_preferences.volume * 100" min="0" max="100"
              step="1" oninput="this.nextElementSibling.value = this.value + '%'">
            <output class="output">{{ user_preferences.volume * 100 + '%' }}</output>
          </div>
          <i class="info">Défini le volume par défaut. Vous pourrez toujours le modifier dans le lecteur.</i>
          <div class="darkContainer">
            <label class="labels" for="fastForward">Durée de l'avance rapide :</label>
            <input id="fastForward" name="fastForward" type="range" :value="user_preferences.fast_forward_duration"
              min="0" max="30" step="5" oninput="this.nextElementSibling.value = this.value + 's'">
            <output class="output">{{ user_preferences.fast_forward_duration + 's' }}</output>
          </div>
          <i class="info">Défini la durée de l'avance rapide.</i>
          <div class="darkContainer">
            <label class="labels" for="rewind">Durée du retour en arrière :</label>
            <input id="rewind" name="rewind" type="range" :value="user_preferences.rewind_duration" min="0" max="30"
              step="5" oninput="this.nextElementSibling.value = this.value + 's'">
            <output class="output">{{ user_preferences.rewind_duration + 's' }}</output>
          </div>
          <i class="info">Défini la durée du retour en arrière.</i>
          <div class="darkContainer">
            <label class="labels" for="watchtimeOffset">Durée de reprise avant la position sauvegardée :</label>
            <input id="watchtimeOffset" name="watchtimeOffset" type="range" :value="user_preferences.watchtime_offset"
              min="0" max="60" step="5" oninput="this.nextElementSibling.value = this.value + 's'">
            <output class="output">{{ user_preferences.watchtime_offset + 's' }}</output>
          </div>
          <i class="info">Défini le nombre de secondes où la vidéo reprendra avant le point de sauvegarde pour se
            remettre dans la scène.</i>
          <div class="darkContainer">
            <input type="checkbox" id="skipIntroCheck" name="skipIntro" :checked="user_preferences.skip_serie_intro_credits">
            <label class="labels" for="skipIntro">Passer les intro et les génériques des séries</label>
          </div>
          <i class="info">Lorsque cette option est activée, les intros et les génériques des séries seront automatiquement
            passés.</i>
          <button @click="modifyPlayerPreferences()">Enregistrer vos préférences de lecteur</button>
        </div>
        <div class="darkTheme">
          <p class="title">Supprimer les lectures en cours</p>
          <div class="watchtimes">
            <ul v-if="watchedMovies && watchedMovies.length">
              <li v-for="item in watchedMovies" v-bind:key="item.id">
                {{ item.name }} - {{ item.annedesortie }}
              </li>
            </ul>
            <p v-else>Vous n'avez pas de lectures en cours.</p>
          </div>
          <div class="watchtimeContainer">
            <button class="valid" @click="deleteWatchtimes()">Tout supprimer</button>
          </div>
        </div>
        <div class="confirmation">
          <div class="confirmationContainer">
            <button class="valid" :onclick="() => deleteAccount()">Supprimer votre compte</button>
            <button class="valid" :onclick="() => modifyPreferences()">Enregistrer vos préférences</button>
          </div>
        </div>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

import NavbarTop from './NavbarTop.vue';
import FooterContainer from './FooterContainer.vue';

export default {
  name: 'SettingsContainer',
  components: {
    NavbarTop,
    FooterContainer,
  },
  data() {
    return {
      limit: 0,
      user: undefined,
      link: "https://api.heavenflix.fr",
      wantStats: undefined,
      wantCarrousel: undefined,
      wantDeleteAccount: false,
      wantWideCards: JSON.parse(localStorage.getItem('wantWideCards')) == true ? true : false,
      exampleMovie: {
        posterURL: 'https://api.heavenflix.fr/images/shutter-island.webp',
        backgroundURL: 'https://api.heavenflix.fr/images/background/shutter-island.webp',
        name: 'Shutter Island',
        cat: 'Drame & Thriller',
      },
      watchedMovies: [],
      user_preferences: undefined,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    modifyPlayerPreferences: async function modifyPlayerPreferences() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          autoplay: document.getElementById('autoplayCheck').checked,
          fullscreen_auto: document.getElementById('fullscreenCheck').checked,
          save_watchtime: document.getElementById('saveWatchtimeCheck').checked,
          playback_speed: parseFloat(document.getElementById('playbackSpeed').value),
          volume: document.getElementById('volume').value / 100,
          fast_forward_duration: parseInt(document.getElementById('fastForward').value),
          rewind_duration: parseInt(document.getElementById('rewind').value),
          watchtime_offset: parseInt(document.getElementById('watchtimeOffset').value),
          skip_serie_intro_credits: document.getElementById('skipIntroCheck').checked,
        })
      };
      await fetch(this.link + '/changeUserPlayerPreferences/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (!data || data.code != 200) return this.showNotification('danger', 'Une erreur est survenue lors de l\'enregistrement de vos préférences de lecteur.', 3000);
        this.showNotification('success', 'Vos préférences de lecteur ont été enregistrées avec succès.', 3000);
        this.getUserPreferences();
      })
    },
    async getUserPreferences() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getUserPreferences/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.user_preferences = JSON.parse(data[0].player_preferences);
      })
    },
    deleteWatchtimes: async function deleteWatchtimes() {
      //if (!this.watchedMovies.length) return this.showNotification('danger', 'Vous n\'avez pas de lectures en cours.', 3000);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/watchtime/user/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (!data) return console.log(data);
        if (data.code == 200) {
          this.showNotification('success', 'Vos lectures en cours ont été supprimées avec succès.', 3000);
          this.getWatchedMovies();
          return;
        }
      })
    },
    getWatchedMovies: async function getWatchedMovies() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getWatchedMovies/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        if (data && data.length <= 0) return this.watchedMovies = [];
        if (data.length > 0) {
          this.watchedMovies = [];
          data.forEach(element => {
            element.cat = JSON.parse(element.cat);
            element.cat = element.cat.join(' & ');
            this.watchedMovies.push(element);
          });
        }
      })
    },
    showNotification(type, text, timing) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: timing,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    setWantWideCards: async function setWantWideCards(value) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/changeUserWantWideCards/' + localStorage.getItem('userId') + '/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return this.showNotification('danger', data.msg, 3000);
        localStorage.setItem('wantWideCards', value);
        this.wantWideCards = value;
        if (value == true) this.showNotification('success', 'Les articles seront désormais affichés en format paysage.', 3000);
        else this.showNotification('success', 'Les articles seront désormais affichés en format portrait.', 3000);
      })
    },
    deleteAccount: async function deleteAccount() {
      if (this.wantDeleteAccount == false) {
        this.wantDeleteAccount = true;
        this.showNotification('warning', 'Cliquez à nouveau pour supprimer votre compte avant 7 secondes pour confirmer.', 7000);
        setTimeout(() => {
          this.wantDeleteAccount = false;
          this.showNotification('danger', 'Suppression de compte annulée.', 3000);
        }, 7000)
      } else {
        const requestOptions = {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(this.link + '/autoDelete/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
          if (data.msg) return this.showNotification('danger', data.msg, 3000);
          this.showNotification('success', 'Votre compte a été supprimé avec succès. Adios.', 3000);
          setTimeout(() => {
            this.disconnect();
          }, 3000)
        })
      }
    },
    disconnect: async function disconnect() {
      //requête pour logout l'utilisateur
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/logout/' + localStorage.getItem('token'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
      })
      localStorage.clear();
      this.$router.push('/login');
    },
    getUser: async function getUser() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getUser/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.user = data;
        localStorage.setItem('limit', data[0].limitCards);
        this.wantStats = data[0].wantStats;
        this.limit = localStorage.getItem('limit');
        this.wantCarrousel = data[0].wantCarrousel;
      })
      const check = document.getElementById("darkCheck");
      let userDark = JSON.parse(localStorage.getItem('darkMode'));
      if (userDark) return check.checked = true;
      check.checked = false;
    },
    modifyPreferences: async function modifyPreferences() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          limit: document.getElementById('limitRange').value,
          mode: document.getElementById('darkCheck').checked,
          stats: document.getElementById('statsCheck').checked,
          wantCarrousel: document.getElementById('carrouselCheck').checked,
        })
      };
      await fetch(this.link + '/changeUserPreferences/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        localStorage.setItem('wantCarrousel', document.getElementById('carrouselCheck').checked);
        this.showNotification('success', 'Vos préférences ont été enregistrées avec succès.', 3000);
        setTimeout(() => {
          location.reload();
        }, 3000)
      })
    },
    changeName: async function changeName() {
      if (document.getElementById('username').value === this.user[0].name) return this.showNotification('danger', 'Vous avez déjà ce nom d\'utilisateur.', 3000);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "name": document.getElementById('username').value, })
      };
      await fetch(this.link + '/changeUserName/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.showNotification('success', 'Votre nom d\'utilisateur est désormais ' + document.getElementById('username').value + '.', 3000);
        setTimeout(() => {
          this.disconnect();
        }, 3000)
      })
    },
    changePassword: async function changePassword() {
      if (!document.getElementById('password').value) return alert("Vous n'avez pas défini de nouveau mot de passe.");
      let text = "Etes vous sûr de vouloir changer de mot de passe ?";
      if (confirm(text) == false) return alert('Vous avez annulé la modification.');
      alert("Votre mot de passe a été changé.\nN'oubliez pas d'utiliser votre nouveau mot de passe au moment de vous connecter.");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "password": document.getElementById('password').value, })
      };
      await fetch(this.link + '/changeUserPassword/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.disconnect();
      })
    },
    changeEmail: async function changeEmail() {
      if (document.getElementById('email').value === this.user[0].email) return this.showNotification('danger', 'Vous avez déjà cette adresse mail.', 3000);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "email": document.getElementById('email').value, })
      };
      await fetch(this.link + '/changeUserEmail/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.showNotification('success', 'Votre adresse mail est désormais ' + document.getElementById('email').value + '.', 3000);
      })
    },
  },
  created: function () {
    this.getUser();
    this.getWatchedMovies();
    this.getUserPreferences();
  },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

* {
  padding: 0;
  margin: 0;
  transition: 350ms ease-in-out;
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $dark-color;

  .settingsWrapper {
    width: 80%;
    margin: 30px auto 0 auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 150px;

    .back {
      width: 71%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0;

      a {
        color: white;
        text-decoration: none;
        font-size: 18px;
      }
    }

    .profil {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .confirmation {
        margin-top: 20px;
        padding-bottom: 150px;

        .confirmationContainer {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          button {
            background: transparent;
            color: white;
            border: none;
            font-size: 16px;
            border-bottom: 2px solid $dark-red;
            padding: 5px;
            transition-duration: 0.4s;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              transition-duration: 0.4s;
            }

            &:focus {
              outline: none;
            }

            &:first-child {
              border: 2px solid $dark-red;
              border-radius: 5px;
              padding: 5px 10px;
            }
          }
        }
      }

      .category {
        font-size: 25px;
        font-family: 'Lato';
        color: white;
        border-bottom: 2px solid $dark-red;
        width: fit-content;
        padding: 0 5px 3px 0px;
      }

      h3 {
        font-family: 'Montserrat';
        font-size: 24px;
      }

      .pass {
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 7px;

        i {
          width: 100%;
          color: rgba(255, 255, 255, 0.61);
          font-family: 'Lato';
        }

        .warning {
          color: white;
          font-weight: bold;
          font-size: 18px;
        }

        label {
          font-size: 18px;
          font-family: 'Lato';
        }

        input {
          border: none;
          padding: 8px 15px;
          border-radius: 10px;
          max-width: 300px;
          outline: none;
          background: $light-color;
          color: $back-color;
        }

        button {
          max-width: 330px;
          padding: 5px;
          border: none;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .cardLimit {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: fit-content;

        label {
          font-size: 18px;
          font-family: 'Lato';
        }

        .ouput {
          font-family: 'Montserrat';
        }

        input[type="range"] {
          -webkit-appearance: none;
          margin-right: 15px;
          width: 200px;
          height: 7px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          background-image: linear-gradient(#ff4500, #ff4500);
          background-size: 0% 100%;
          background-repeat: no-repeat;
        }

        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: $dark-red;
          cursor: ew-resize;
        }

        i {
          width: 100%;
          color: rgba(255, 255, 255, 0.61);
          font-family: 'Lato';
        }
      }

      .preferences {
        display: flex;
        flex-direction: column;
        gap: 5px !important;

        i {
          margin: 0 0 20px 0;
        }

        button {
          max-width: 330px;
          padding: 5px;
          border: none;
          background: $back-color;
          color: white;
          border-radius: 5px;
          padding: 10px 0;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .darkTheme {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .title {
          font-size: 22px;
          font-family: 'Montserrat';
          color: white;
          margin: 10px 0;
        }

        .watchtimes {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            color: white;
            font-family: 'Lato';
            font-size: 18px;
            font-weight: bold;
          }

          li {
            padding: 5px 0;
          }

          p {
            font-family: 'Lato';
            font-size: 18px;
            color: white;
            margin-left: 10px;
          }
        }

        .watchtimeContainer {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 10px;
          padding: 10px 0;

          button {
            background: black;
            color: white;
            border: none;
            font-size: 16px;
            border: 1px solid $soft-grey;
            padding: 10px;
            transition-duration: 0.4s;
            border-radius: 5px;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              transition-duration: 0.4s;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .cardsContainer {
          display: flex;
          gap: 20px;
          padding: 20px 0;

          .cardActive {
            .details {

              .selection {
                svg {
                  color: $dark-red;
                }
              }
            }
          }

          .cardPoster {
            position: relative;
            width: 300px;
            display: flex;
            flex-direction: column;

            img {
              width: 100%;
              aspect-ratio: 2/3;
              border-radius: 10px 10px 0 0;
              filter: brightness(0.5);
            }

            .details {
              background: rgba(0, 0, 0, 0.5);
              max-width: 100%;
              border-radius: 0 0 10px 10px;
              padding: 10px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .nameCat {
                width: 100%;

                h4 {
                  font-family: 'Montserrat';
                  font-size: 18px;
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                p {
                  font-family: 'Lato';
                  font-size: 16px;
                  font-weight: bold;
                  color: rgba(255, 255, 255, 0.61);
                }
              }

              svg {
                width: 30px;
                height: 30px;
                color: transparent;
                transition-duration: 0.3s;
              }
            }

            &:hover {
              cursor: pointer;

              img {
                filter: brightness(1);
              }
            }
          }

          .cardWide {
            position: relative;
            width: 350px;
            display: flex;
            flex-direction: column;

            img {
              width: 100%;
              aspect-ratio: 5/3;
              border-radius: 10px 10px 0 0;
              object-fit: cover;
              filter: brightness(0.5);
            }

            .details {
              background: rgba(0, 0, 0, 0.5);
              max-width: 100%;
              border-radius: 0 0 10px 10px;
              padding: 10px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .nameCat {
                width: 100%;

                h4 {
                  font-family: 'Montserrat';
                  font-size: 18px;
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                p {
                  font-family: 'Lato';
                  font-size: 16px;
                  font-weight: bold;
                  color: rgba(255, 255, 255, 0.61);
                }
              }

              svg {
                width: 30px;
                height: 30px;
                color: transparent;
                transition-duration: 0.3s;
              }
            }

            &:hover {
              cursor: pointer;

              img {
                filter: brightness(1);
              }
            }
          }
        }

        .darkContainer {
          display: flex;
          gap: 5px;

          label {
            font-size: 16px;
            font-family: 'Lato';
          }
        }

        i {
          width: 100%;
          color: rgba(255, 255, 255, 0.61);
          font-family: 'Lato';
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .settingsWrapper {
      width: 100%;
      text-align: start;
      padding: 0;

      .back {
        width: 95%;
      }

      .profil {
        width: 90%;

        .pass {
          input {
            width: 75%;
          }

          button {
            width: 79%;
          }
        }

        .pass {

          i {
            width: 90%;
          }
        }

        .cardLimit {

          i {
            width: 90%;
          }
        }

        .darkTheme {

          i {
            width: 90%;
          }
        }
      }
    }
  }
}
</style>
