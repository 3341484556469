<template>
  <div class="mainContainer">
    <NavbarTop />
    <div class="formContainer">
      <div class="form">
        <div class="formTitle">
          <h1>Rechercher un film ou une série à ajouter</h1>
          <p>Cliquez sur le film ou la série pour le/la séléctionner. Vous ne trouvez pas le film ou la série ? Faites
            une
            demande manuelle dans la section dessous.</p>
          <p>En cas de non-résultat, tentez plusieurs syntaxes différentes.</p>
        </div>
        <div class="formContent">
          <div class="formInput">
            <input type="text" id="search" placeholder="Rechercher" />
            <select name="type" id="type">
              <option value="movie">Film</option>
              <option value="series">Série</option>
            </select>
          </div>
          <div class="formButton">
            <button @click="searchMovie()">Rechercher</button>
          </div>
        </div>
      </div>
    </div>
    <div class="manuallySection">
      <button class="switchManually" @click="toggleShowDemandes()">Voir vos demandes<span v-if="!showDemandes"><i
            class="fa-solid fa-plus"></i></span><span v-else><i class="fa-solid fa-minus"></i></span></button>
      <div class="manuallyCard" v-if="showDemandes">
        <div class="manuallyForm" v-if="showDemandes">
          <div class="formTitle">
            <p>Retrouvez ci-dessous toutes vos demandes d'ajouts.</p>
          </div>
          <div class="tableContainer" v-if="userDemandes && userDemandes.length > 0">
            <table>
              <thead>
                <tr>
                  <th v-if="screenWidth > 426">Date</th>
                  <th>Titre</th>
                  <th v-if="screenWidth > 426">Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-for="item in userDemandes" v-bind:key="item.id">
                <tr>
                  <td v-if="screenWidth > 426">{{ item.date }}</td>
                  <td>{{ item.title }}</td>
                  <td v-if="screenWidth > 426">{{ item.type }}</td>
                  <td>{{ item.status }}</td>
                  <td><button @click="deleteDemandes(item.id)">Supprimer</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tableContainer" v-else>
            <p>Aucun résultat trouvé, vous n'avez effectué aucune demande.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="manuallySection">
      <button class="switchManually" @click="toggleManuallyForm()">Faire une demande manuelle<span
          v-if="!showManuallyForm"><i class="fa-solid fa-plus"></i></span><span v-else><i
            class="fa-solid fa-minus"></i></span></button>
      <div class="manuallyCard" v-if="showManuallyForm">
        <div class="manuallyForm" v-if="showManuallyForm">
          <div class="formTitle">
            <p>Remplissez le formulaire ci-dessous pour demander un film ou une série.</p>
            <p>* Le titre doit être suffisant pour retrouver l'article via Google.</p>
            <p>** L'année est facultative mais aide à avoir la bonne version. Si vous ne la connaissez pas, laissez
              "Année" dans le menu déroulant.</p>
          </div>
          <div class="formContent">
            <div class="formInput">
              <input type="text" id="name" placeholder="Titre réel ou approximatif." />
              <select type="year" id="year">
                <option value="">Année</option>
              </select>
              <select name="type" id="typeWant">
                <option value="movie">Film</option>
                <option value="series">Série</option>
              </select>
            </div>
            <div class="formButton">
              <button @click="createDemande()">Envoyer la demande <svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-send">
                  <line x1="22" y1="2" x2="11" y2="13"></line>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="resultSection">
      <div class="resultCard" v-for="item in results" v-bind:key="item.id" @click="selectCard(item)" :id="item.imdbID">
        <div>
          <img :src="item.Poster" :alt="'Affiche du film ' + item.Title"
            @error="this.src = 'https\://api.heavenflix.fr/images/error.webp'" />
          <p>{{ item.Title }} | <span>{{ item.Year }}</span></p>
        </div>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
import NavbarTop from '../components/NavbarTop.vue'
import FooterContainer from './FooterContainer.vue';
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'AskContainer',
  components: {
    NavbarTop,
    FooterContainer,
  },
  data() {
    return {
      API_KEY: '22599dd8',
      link: "https://api.heavenflix.fr",
      results: undefined,
      showManuallyForm: false,
      showDemandes: false,
      userDemandes: undefined,
      screenWidth: window.innerWidth || document.documentElement.clientWidth,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
    };
  },
  methods: {
    deleteDemandes: async function deleteDemandes(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteDemande/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', 'Demande supprimée', 3000);
        this.getDemandes();
      });
    },
    getDemandes: async function getDemandes() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getDemandes/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        data.forEach(element => {
          if (element.status == null) element.status = 'En attente';
          if (element.type == 'movie') element.type = 'Film';
          if (element.type == 'series') element.type = 'Série';
          element.date = this.formatDate(element.date);
        });
        this.userDemandes = data;
      });
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric", // Ajout de l'heure
        minute: "numeric", // Ajout des minutes
        hour12: false,
        timeZone: "Europe/Paris"
      };
      const date = new Date(dateString);
      const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
      return formattedDate;
    },
    selectCard: async function selectCard(card) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ title: card.Title, year: card.Year, type: card.Type, imdbID: card.imdbID, userId: localStorage.getItem('userId') }),
      };
      await fetch(this.link + '/addDemande', requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', 'Demande envoyée', 3000);
        this.getDemandes();
        this.results = undefined;
      });
    },
    createDemande: async function createDemande() {
      const name = document.getElementById('name').value;
      const year = document.getElementById('year').value;
      const type = document.getElementById('typeWant').value;
      if (name == '') return this.showNotification('warning', 'Veuillez entrer un titre de film/série', 3000);
      if (type == '') return this.showNotification('warning', 'Veuillez choisir un type', 3000);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ title: name, year: year, type: type, userId: localStorage.getItem('userId') }),
      };
      await fetch(this.link + '/addDemande', requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', 'Demande envoyée', 3000);
        this.getDemandes();
      });
    },
    updateYears: async function updateYears() {
      // Récupérer l'élément select
      const selectYear = document.getElementById('year');

      // Obtenir l'année actuelle
      const currentYear = new Date().getFullYear();

      // Boucler à partir de l'année actuelle jusqu'à 1895 (inverser l'ordre)
      for (let year = currentYear; year >= 1895; year--) {
        // Créer une option pour chaque année
        const option = document.createElement('option');
        option.value = year;
        option.text = year;

        // Ajouter l'option à l'élément select
        selectYear.appendChild(option);
      }
    },
    toggleShowDemandes: async function toggleShowDemandes() {
      this.showDemandes = !this.showDemandes;
    },
    toggleManuallyForm: async function toggleManuallyForm() {
      this.showManuallyForm = !this.showManuallyForm;
      if (this.showManuallyForm) setTimeout(() => {
        this.updateYears();
      }, 200);
    },
    searchMovie: async function searchMovie() {
      let value = document.getElementById('search').value;
      const type = document.getElementById('type').value;
      if (value == '') return this.showNotification('warning', 'Veuillez entrer un titre de film/série', 3000);
      value = value.toLowerCase();
      this.results = undefined;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/askToOMBD/' + value + '/' + type, requestOptions).then((res) => res.json()).then(data => {
        data = data['Search'];
        if (!data || data.Error) return this.showNotification('danger', 'Aucun(e) film/série trouvé(e) avec ce titre', 3000);
        for (let i = 0; i < data.length; i++) {
          if (!data[i].Poster.includes('https://')) {
            data.splice(i, 1);
            i--;  // Décrémente i pour compenser la suppression de l'élément
          }
        }
        this.results = data;
        if (this.results == undefined || this.results.length < 1) return this.showNotification('danger', 'Aucun(e) film/série trouvé(e) avec ce titre', 3000);
      });
    },
    showNotification(type, text, duration) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: duration,
      })
    },
  },
  created: function () {

  },
  mounted: async function () {
    this.getDemandes();
  },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $dark-color;

  .manuallySection {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    .switchManually {
      max-width: 60%;
      width: 60%;
      border-radius: 10px;
      background: $back-color;
      border: none;
      color: $light-color;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 15px 15px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;


      span {
        font-size: 1rem;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.575);
        font-family: 'Lato';
      }
    }

    .manuallyCard {
      position: relative;
      width: 100%;
      max-width: 60%;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;

      .manuallyForm {
        background: $back-color;
        max-width: 100%;
        width: 100%;
        padding: 20px 15px;
        border-radius: 10px;


        .formTitle {

          p {
            font-size: 1rem;
            color: rgba(255, 255, 255, 0.575);
            font-family: 'Lato';

            &:first-child {
              color: $light-color;
              font-family: 'Montserrat';
              font-size: 1.1rem;
            }
          }
        }

        .tableContainer {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 25px;
          overflow: hidden;
          overflow-x: auto;

          p {
            font-size: 1rem;
            color: rgba(255, 255, 255, 0.575);
            font-family: 'Lato';
            text-align: center;
          }

          table {
            width: 100%;
            background: $dark-color;
            padding: 20px 20px;
            border-radius: 15px;
            color: white;
            overflow-x: auto;

            thead {
              tr {
                th {
                  font-family: 'Montserrat';
                  font-size: 18px;
                  padding: 10px 0 10px 5px;
                  text-align: center;
                }
              }

              background: $dark-color;
            }

            tbody {
              tr {
                td {
                  font-family: 'Montserrat';
                  font-size: 16px;
                  padding: 7px 0;
                  text-align: center;
                }

                &:nth-child(odd) {
                  background: rgba(255, 255, 255, 0.1);
                }

                button {
                  border: none;
                  padding: 5px 15px;
                  background: $dark-red;
                  color: white;
                  font-family: "Montserrat";
                  font-size: 14px;
                  border-radius: 10px;

                  &:hover {
                    cursor: pointer;
                  }

                }
              }
            }
          }
        }

        .formContent {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .formInput {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;
            padding-top: 25px;

            input {
              position: relative;
              width: 100%;
              max-width: 400px;
              height: 50px;
              padding: 0 20px;
              border: none;
              border-radius: 10px;
              background: $soft-grey;
              color: $light-color;
              font-size: 1.2rem;
              outline: none;
              transition: all 0.3s ease-in-out;

              &:focus {
                background: $dark-color;
              }
            }

            select {
              position: relative;
              width: 100%;
              max-width: 150px;
              height: 50px;
              padding: 0 20px;
              border: none;
              border-radius: 10px;
              background: $soft-grey;
              color: $light-color;
              font-size: 1.2rem;
              outline: none;
              transition: all 0.3s ease-in-out;

              &:focus {
                background: $dark-color;
              }
            }
          }

          .formButton {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0;

            button {
              position: relative;
              width: 100%;
              max-width: 400px;
              height: 50px;
              padding: 0 20px;
              border: none;
              border-radius: 10px;
              background: $dark-red;
              color: $light-color;
              font-size: 1.2rem;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              svg {
                width: 25px;
                height: 25px;
              }
            }
          }

        }
      }
    }
  }

  .resultSection {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding: 50px 0 150px 0;

    .resultCard {
      position: relative;
      width: 300px;
      aspect-ratio: 2/3;
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      ;

      img {
        width: 300px;
        aspect-ratio: 2/3;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        transition: all 0.3s ease-in-out;
      }

      p {
        margin-top: 5px;
        position: relative;
        max-width: 90%;
        font-size: 1.2rem;
        font-weight: bold;
        color: $light-color;
        font-family: 'Montserrat';
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 1rem;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.575);
          font-family: 'Lato';
        }
      }

      &:hover {
        img {
          filter: brightness(0.5);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .formContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    .form {
      position: relative;
      max-width: 60%;
      width: 60%;
      display: flex;
      flex-direction: column;
      background: $back-color;
      border-radius: 10px;

      .formTitle {
        position: relative;
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        padding: 10px 35px 0 20px;


        h1 {
          font-size: 1.4rem;
          color: $light-color;
          font-family: 'Montserrat';
        }

        p {
          margin: 5px 0;
          font-size: 1rem;
          color: rgba(255, 255, 255, 0.575);
          font-family: 'Lato';

          &:last-child {
            font-size: 1.15rem;
            font-weight: 600;
            color: #e60036;
            font-family: 'Montserrat';
          }
        }
      }

      .formContent {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .formInput {
          position: relative;
          width: 95%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 25px;
          padding-top: 25px;

          input {
            position: relative;
            width: 100%;
            height: 50px;
            padding: 0 20px;
            border: none;
            border-radius: 10px;
            background: $soft-grey;
            color: $light-color;
            font-size: 1.2rem;
            outline: none;
            transition: all 0.3s ease-in-out;

            &:focus {
              background: $dark-color;
            }
          }

          select {
            position: relative;
            width: 100%;
            max-width: 150px;
            height: 50px;
            padding: 0 25px;
            border: none;
            border-radius: 10px;
            background: $soft-grey;
            color: $light-color;
            font-size: 1.2rem;
            outline: none;
            transition: all 0.3s ease-in-out;

            &:focus {
              background: $dark-color;
            }
          }
        }

        .formButton {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 0;

          button {
            position: relative;
            width: 100%;
            max-width: 400px;
            height: 50px;
            padding: 0 20px;
            border: none;
            border-radius: 10px;
            background: $dark-red;
            color: $light-color;
            font-size: 1.2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    .manuallySection {
      .switchManually {
        max-width: 80%;
        width: 80%;
      }

      .manuallyCard {
        max-width: 80% !important;
        width: 80% !important;

        .manuallyForm {
          .formContent {
            .formInput {
              input {
                max-width: 300px;
              }
            }
          }
        }
      }
    }

    .resultSection {
      .resultCard {
        width: 250px;
      }
    }

    .formContainer {
      .form {
        max-width: 80%;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    .manuallySection {
      .switchManually {
        max-width: 90%;
        width: 90%;
      }

      .manuallyCard {
        max-width: 90% !important;
        width: 90% !important;

        .manuallyForm {
          .formContent {
            .formInput {
              flex-wrap: wrap;
            }
          }

          .tableContainer {
            table {
              min-width: 100%;
              width: fit-content;

              tbody {
                tr {
                  td {
                    width: 110px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .resultSection {
      .resultCard {
        width: 250px;
      }
    }

    .formContainer {
      .form {
        max-width: 90%;
        width: 90%;

        .formTitle {
          padding: 10px;
        }

        .formContent {

          .formInput {
            flex-wrap: wrap;

            input {
              max-width: 300px;
            }
          }

          .formButton {
            button {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .mainContainer {
    .manuallySection {
      .switchManually {
        max-width: 95%;
        width: 95%;
      }

      .manuallyCard {
        max-width: 95% !important;
        width: 95% !important;

        .manuallyForm {
          padding: 10px 10px;

          .formContent {
            .formInput {
              input {
                max-width: 90% !important;
                width: 90%;
              }
            }
          }

          .tableContainer {
            padding-top: 5px;

            table {
              min-width: 100%;
              width: fit-content;
              padding: 5px;

              tbody {
                tr {
                  td {
                    width: 110px;

                    button {
                      padding: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .resultSection {
      gap: 50px;

      .resultCard {
        width: 350px;
      }
    }

    .formContainer {
      .form {
        max-width: 95%;
        width: 95%;

        .formTitle {
          padding: 10px;
        }

        .formContent {

          .formInput {
            input {
              max-width: 90% !important;
              width: 90%;
            }
          }

          .formButton {
            button {
              max-width: 250px;
            }
          }
        }
      }
    }
  }
}
</style>
