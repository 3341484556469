<template>
  <div class="homePage">
    <NavbarTop />
    <div class="manualCheckerContainer" v-if="isManualChecking">
      <div class="manualChecker">
        <h2>🔍 Scan manuel</h2>
        <p>{{ this.remainingTime }}</p>
        <p v-if="disabledMoviesFromManualChecking && disabledMoviesFromManualChecking.length > 0">Films désactivés : {{
      disabledMoviesFromManualChecking.length }}</p>
        <div class="disabledMovieContainer">
          <p v-for="element in disabledMoviesFromManualChecking" v-bind:key="element.id"
            :id="'disabled-movie-' + element.id">{{ element }}</p>
        </div>
      </div>
    </div>
    <div class="serverContainer" v-if="serverState">
      <div class="server">
        <div class="serverTitle">
          <div v-if="serverState.isChecking" :class="'serverIcon'">
            <span>🚀</span>
          </div>
          <div v-else-if="serverState.isChecking == false && serverState.canWork == true" :class="'serverIcon'">
            <span>👍</span>
          </div>
          <div v-else-if="serverState.isChecking == false && serverState.canWork == false" :class="'serverIcon'">
            <span>🏝️</span>
          </div>
          <h2>{{ serverState.name }}</h2>
        </div>
        <p>Etat du serveur :
          <span v-if="serverState && serverState.isChecking">Une analyse est en cours..</span>
          <span v-else>En fonctionnement</span>
        </p>
        <p>Dernière analyse : <span>{{ formatDate(serverState.lastCheck) }}</span></p>
        <div class="darkiboxContainer" v-if="darkiboxInfos">
          <p>PREMIUM activé : <span v-if="darkiboxInfos.result.premium">Oui</span><span v-else>Non</span></p>
          <p>Date d'expiration du PREMIUM : <span>{{ formatDate(darkiboxInfos.result.premium_expire) }}</span></p>
        </div>
        <div class="showOptionsButton" @click="toggleDropdown()">
          <p>Gérer le serveur</p>
          <div v-if="!showOptions"><i class="fa-solid fa-plus"></i></div>
          <div v-else><i class="fa-solid fa-minus"></i></div>
        </div>
        <div class="options" ref="myElement">
          <button @click="disableAllMovies()">❌ Désactiver tous les films</button>
          <button @click="enableAllMovies()">✅ Activer tous les films</button>
          <button @click="disableAllSeries()">❌ Désactiver toutes les séries</button>
          <button @click="enableAllSeries()">✅ Activer toutes les séries</button>
          <button @click="disableAllSeasons()">❌ Désactiver toutes les saisons</button>
          <button @click="enableAllSeasons()">✅ Activer toutes les saisons</button>
          <div v-if="serverState.canWork"><button @click="fatouCantWork()">🏝️ Mettre Fatou en RTT</button></div>
          <div v-else><button @click="fatouCanWork()">🔨 Remettre Fatou à son poste</button></div>
          <div v-if="!serverState.isChecking"><button @click="goFatou()">🚀 Lancer un scan avec Fatou</button></div>
          <div v-else><button @click="stopFatou()">⛔ Arrêter le scan de Fatou</button></div>
          <button @click="manualChecking()">🔍 Vérifier les films manuellement</button>
          <button @click="forceStop()">⚠️ Forcer l'arret en BDD</button>
          <button @click="downloadLastSave()">💾 Télécharger la dernière sauvegarde</button>
          <button @click="getMoviesToDownloadLink()">📼 Liens de téléchargement</button>
          <button @click="verifyMoviesFiles()">🔍 Vérifier les fichiers</button>
          <button @click="setMoviesFilesData()">🔃 MàJ données films</button>
        </div>
        <div class="statsTable">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Activés</th>
                <th>Désactivés</th>
                <th>Uploadés</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Films</td>
                <td>{{ totalEnabledMovies }}</td>
                <td>{{ totalDisabledMovies }}</td>
                <td>{{ totalUploadedMovies }}</td>
                <td>{{ totalMovies }}</td>
              </tr>
              <tr>
                <td>Séries</td>
                <td>{{ totalEnabledSeries }}</td>
                <td>{{ totalDisabledSeries }}</td>
                <td>..</td>
                <td>{{ totalSeries }}</td>
              </tr>
              <tr>
                <td>Saisons</td>
                <td>{{ totalEnabledSeasons }}</td>
                <td>{{ totalDisabledSeasons }}</td>
                <td>..</td>
                <td>{{ totalSeasons }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="statsTable" v-if="downloads && downloads.length > 0">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Article</th>
                <th>Type</th>
                <th>Status</th>
                <th>Créé le</th>
                <th>Downloaded</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="element in downloads" v-bind:key="element.id" :id="'download-' + element.id">
                <td>{{ element.id }}</td>
                <td>{{ element.article_id }}</td>
                <td>{{ element.type }}</td>
                <td>{{ element.status }}</td>
                <td>{{ formatDate(element.created_at) }}</td>
                <td>{{ element.downloaded ? 'Oui' : 'Non' }}</td>
                <td>
                  <button @click="uploadDownload(element.article_id)">Uploader</button>
                  <button @click="deleteDownload(element.id)">Supprimer</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="statsTable">
          <table>
            <thead>
              <tr>
                <th>Dernière co.</th>
                <th>Email</th>
                <th>Username</th>
                <th>Admin</th>
                <th>Banni</th>
                <th>Promo/down</th>
                <th>Ban/déban</th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="element in users" v-bind:key="element.id" :id="'user-' + element.id">
                <td>{{ formatDate(element.lastLogin) }}</td>
                <td>{{ element.email == 'Oui' ? element.email : '..' }}</td>
                <td>{{ element.name }}</td>
                <td>{{ element.isAdmin ? 'Oui' : '..' }}</td>
                <td>{{ element.isban ? 'Oui' : '..' }}</td>
                <td v-if="element.isAdmin == true"><button class="tableButtonDowngrade"
                    @click="unsetAdmin(element.id, element.name)">Downgrade</button>
                </td>
                <td v-else><button @click="setAdmin(element.id, element.name)"
                    class="tableButtonPromotion">Promouvoir</button></td>
                <td v-if="element.isban == true"><button @click="unbanUser(element.id, element.name)"
                    class="tableButtonUnban">Débannir</button></td>
                <td v-else><button @click="banUser(element.id, element.name)" class="tableButtonBan">Bannir</button>
                </td>
                <td><button class="tableButtonDelete" @click="deleteUser(element.id, element.name)">Supprimer</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--<div class="legende">
          <span>
            <p><span>🚀</span> Analyse en cours</p>
          </span>
          <span>
            <p><span>👍</span> Analyse terminée</p>
          </span>
          <span>
            <p><span>🏝️</span> Fatou en congés</p>
          </span>
        </div>-->
      </div>
    </div>
    <div class="collectionDisplayerContainer">
      <div class="collectionDisplayerButton" @click="toggleDisplayCollections">
        <p>Gérer les collections de films</p>
        <div v-if="showCollections"><span><i class="fa-solid fa-minus"></i></span></div>
        <div v-else><span><i class="fa-solid fa-plus"></i></span></div>
      </div>
    </div>
    <div class="collectionAddContainer" v-show="showCollections" name="fade">
      <div class="collectionAdd">
        <h2>Ajouter une collection :</h2>
        <input name="inputCollection" id="inputCollection" type="text" placeholder="Nom de collection">
        <button @click="addToCollections()">Ajouter</button>
      </div>
      <div class="collectionsContainer" v-show="showCollections">
        <ul>
          <li v-for="element in collections" v-bind:key="element.id" :id="'collection-' + element.id">
            <p>{{ element.name }} </p>
            <div class="collectionsOptions">
              <input type="text" :id="'collection-' + element.id + '-input'" :placeholder="element.posterURL">
              <button @click="changePosterCollection(element.id, element.name)">Editer</button>
              <button @click="deleteCollections(element.id)">Supprimer</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="collectionDisplayerContainer">
      <div class="collectionDisplayerButton" @click="toggleDisplayRequests">
        <p>Consulter les requêtes (<span v-if="requests && requests.length > 0">{{ requests.length }}</span><span
            v-else>0</span>)</p>
        <div v-if="showRequests"><span><i class="fa-solid fa-minus"></i></span></div>
        <div v-else><span><i class="fa-solid fa-plus"></i></span></div>
      </div>
    </div>
    <div class="requestAddContainer" v-show="showRequests" name="fade">
      <table v-if="requests && requests.length > 0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Requête</th>
            <th>Utilisateur</th>
            <th>Raison</th>
            <th>Seen</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in requests" :key="element.id" :id="'collection-' + element.id">
            <td>{{ formatDate(element.created_at) }}</td>
            <td>{{ element.request }}</td>
            <td>{{ element.username }}</td>
            <td>{{ element.reason ? element.reason : 'N/A' }}</td>
            <td>{{ element.isSeen ? 'Vu' : '..' }}</td>
            <td class="tableActions">
              <router-link
                :to="{ name: 'player', params: { type: element.isMovie ? 'movie' : 'serie', index: element.episode ? element.episode - 1 : 'noindex', id: element.requestFilm, season: element.season ? element.season : 'noseason' } }"
                title="Voir le film">
                Lire</router-link>
              <span v-if="!element.isDone">
                <button @click="doneRequest(element.id)">Traiter</button>
              </span>
              <span v-else>
                <button class="requestDone" disabled>Done</button>
              </span>
              <button @click="deleteRequest(element.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>Aucun résultat.</p>
      </div>
    </div>

    <div class="collectionDisplayerContainer">
      <div class="collectionDisplayerButton" @click="toggleDisplayDemandes">
        <p>Consulter les demandes (<span v-if="demandes && demandes.length > 0">{{ demandes.length }}</span><span
            v-else>0</span>)</p>
        <div v-if="showDemandes"><span><i class="fa-solid fa-minus"></i></span></div>
        <div v-else><span><i class="fa-solid fa-plus"></i></span></div>
      </div>
    </div>
    <div class="requestAddContainer" v-show="showDemandes" name="fade">
      <table v-if="demandes && demandes.length > 0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Utilisateur</th>
            <th>Titre</th>
            <th>Année</th>
            <th>Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in demandes" v-bind:key="element.id" :id="'collection-' + element.id">
            <td>{{ element.date }}</td>
            <td>{{ element.username }}</td>
            <td>{{ element.title }}</td>
            <td>{{ element.year ? element.year : 'N/A' }}</td>
            <td>{{ element.type }}</td>
            <td>{{ element.status }}</td>
            <td class="tableActions">
              <button @click="validateDemande(element.id)">Valider</button>
              <button @click="refuseDemande(element.id, 'Indisponible')">Décliner</button>
              <button @click="refuseDemande(element.id, 'Existe déjà')">Copie</button>
              <button @click="deleteDemande(element.id)">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>Aucun résultat.</p>
      </div>
    </div>
    <div class="collectionDisplayerContainer">
      <div class="collectionDisplayerButton">
        <input id="search" type="search" placeholder="Rechercher un film désactivé 🎥" @change="searchDisabledMovie()">
        <button><svg :onclick="() => searchDisabledMovie()" alt="Icone de recherche" xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" stroke-width="2"
            stroke="#15161b" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg></button>
      </div>
    </div>
    <div class="cardContainer">
      <div class="noResult" v-if="films == 0 && series == 0">
        <h1>👍 Bonne nouvelle, aucune série ni aucun film désactivés..</h1>
      </div>
      <div class="card" v-for="element in films" v-bind:key="element.id" :id="'card-' + element.id">
        <img :src="element.posterURL" onerror="this.src='https\://api.heavenflix.fr/images/error.webp'"
          loading="lazy" />
        <a :href="element.link" target="_blank">{{ element.name }}</a>
        <span class="year">{{ element.annedesortie }}</span>
        <input type="text" :placeholder="element.link" :id="'input-' + element.id" />
        <div class="interactions">
          <button ref="bouton" v-bind:onclick="() => deleteMovie(element.id)">
            Supprimer
          </button>
          <button ref="bouton" v-bind:onclick="() => editArticle(element.id, 'movie')">
            Editer
          </button>
          <button ref="bouton" v-bind:onclick="() => changeStreamingLink(element.id, element.name)">
            Valider
          </button>
        </div>
      </div>
      <div class="card" v-for="element in series.slice(0, limit)" v-bind:key="element.id"
        :id="'card-serie-' + element.id">
        <img :src="element.posterURL" loading="lazy"
          onerror="this.src='https\://api.heavenflix.fr/images/error.webp'" />
        <a>{{ element.name }}</a>
        <span class="year"><i class="fa-solid fa-calendar-days"></i> {{ element.anneedesortie }} - {{ element.annedefin
          }}</span>
        <div class="interactions">
          <button ref="bouton" v-bind:onclick="() => deleteMovie(element.id)">
            Supprimer
          </button>
          <button ref="bouton" v-bind:onclick="() => activeSerie(element.id)">
            Activer
          </button>
          <button ref="bouton" v-bind:onclick="() => editArticle(element.id, 'serie')">
            Editer
          </button>
        </div>
      </div>
      <div class="seasonCard" v-for="element in seasons" v-bind:key="element.id" :id="'card-seasons-' + element.id">
        <div class="seasonCardTitle">
          <h2>{{ element.serie_name }}</h2>
          <p>Saison {{ element.season }}</p>
        </div>
        <div class="seasonCardRedirects">
          <a v-for="(link, index) in element.episodesLinks" v-bind:key="link" :href="link" target="_blank">Episode
            {{ index + 1 }}</a>
        </div>
        <div class="seasonCardEpisodeInput" v-for="(link, index) in element.episodesLinks" v-bind:key="link">
          <label :for="'input-season-' + element.id">Lien de l'épisode {{ index + 1 }}</label>
          <input :class="'lien-' + element.id" type="text" :value="link" :id="'input-season-' + element.id" />
        </div>
        <div class="seasonCardInteractions">
          <button class="editSeasonButton" @click="editSeasonById(element.id, element.season)">Editer cette
            saison</button>
          <button class="editSeasonButton" @click="enableSeasonById(element.id)">Activer cette
            saison</button>
        </div>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import AOS from 'aos';
import NavbarTop from "../components/NavbarTop.vue";
import FooterContainer from './FooterContainer.vue';
import gsap from 'gsap';

export default {
  name: "AdminContainer",
  components: {
    NavbarTop,
    FooterContainer,
  },
  data() {
    return {
      showOptions: false,
      films: [],
      series: [],
      seasons: [],
      isManualChecking: false,
      link: "https://api.heavenflix.fr",
      darkiboxAPIKey: "4287et9m2dyhtmvmz2f8",
      darkiboxInfos: undefined,
      collections: undefined,
      requests: undefined,
      showCollections: false,
      showRequests: false,
      showDemandes: false,
      serverState: undefined,
      limit: localStorage.getItem('limit'),
      users: undefined,
      totalEnabledMovies: undefined,
      totalDisabledMovies: undefined,
      totalMovies: undefined,
      totalUploadedMovies: undefined,
      totalEnabledSeries: undefined,
      totalDisabledSeries: undefined,
      totalSeries: undefined,
      totalEnabledSeasons: undefined,
      totalDisabledSeasons: undefined,
      totalSeasons: undefined,
      demandes: undefined,
      moviesToScan: undefined,
      remainingTime: 'Chargement..',
      disabledMoviesFromManualChecking: [],
      downloads: undefined,
      moviesToSetDownloadLink: undefined,
    };
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    setMoviesFilesData: async function setMoviesFilesData() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const res = await fetch('https://server.heavenflix.fr/movies/allfiles/infos', requestOptions);
        const data = await res.json();
        if (data.msg) return console.log(data.msg);
        if (data.done) this.showNotification('success', 'Les informations fichiers des films sont mis à jour.')
      } catch (error) {
        console.error('Erreur lors de l\'analyse :', error);
        this.showNotification('danger', 'Une erreur est survenue lors de l\'analyse.');
      }
    },
    verifyMoviesFiles: async function verifyMoviesFiles() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const res = await fetch(this.link + '/getStreamingLinks', requestOptions);
        const data = await res.json();
        if (data.msg) return console.log(data.msg);
        console.log(data);
        this.sendMoviesToCheckFile(data);
      } catch (error) {
        console.error('Erreur lors de l\'analyse :', error);
        this.showNotification('danger', 'Une erreur est survenue lors de l\'analyse.');
      }
    },
    sendMoviesToCheckFile: async function sendMoviesToCheckFile(links) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          files: links,
        })
      };
      await fetch('https://server.heavenflix.fr/check', requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          console.log(data)
          this.showNotification('success', 'Le fichier a été envoyé avec succès.');
        });
    },
    getMoviesToDownloadLink: async function getMoviesToDownloadLink() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        const res = await fetch(this.link + '/allMoviesToCheck', requestOptions);
        const data = await res.json();

        if (data.msg) {
          console.log(data.msg);
          return;
        }

        this.showNotification('success', 'L\'analyse est lancée.');

        for (const [index, element] of data.entries()) {
          if (!element.link.includes('heavenflix')) {
            console.log(`Analyse en cours ${index + 1}/${data.length}`);
            if (index + 1 === data.length) this.showNotification('success', 'L\'analyse est terminée.');
            await this.getDownloadLink(element.link, element.id);
            // Ajoutez une pause de 300ms entre chaque appel à getDownloadLink
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      } catch (error) {
        console.error('Erreur lors de l\'analyse :', error);
        this.showNotification('danger', 'Une erreur est survenue lors de l\'analyse.');
      }
    },
    getDownloadLink: async function getDownloadLink(link, id) {
      if (link.includes('heavenflix')) return console.log(link);
      const code = this.getCodeFromLink(link);
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      await fetch('https://darkibox.com/api/file/direct_link?key=4287et9m2dyhtmvmz2f8&file_code=' + code, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.result && data.result.versions && data.result.versions[0] && data.result.versions[0].url) this.setDownloadLink(id, data.result.versions[0].url);
        });
    },
    setDownloadLink: async function setDownloadLink(id, link) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          link: link,
        })
      };
      await fetch(this.link + '/setDownloadLink/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) { console.log(data.error); }
      });
    },
    uploadDownload: async function uploadDownload(id) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/downloads/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.code != 200) return this.showNotification('danger', data.msg);
          this.getDownloads();
          this.showNotification('success', 'Le téléchargement a été uploadé avec succès.');
        });
    },
    deleteDownload: async function deleteDownload(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/downloads/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.code == '500') return console.log(data.msg);
          this.getDownloads();
          this.showNotification('success', 'Le téléchargement a été supprimé avec succès.');
        });
    },
    doneRequest: async function doneRequest(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/doneRequest/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getAllRequests();
        });
    },
    disableMovie: async function disableMovie(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/disableMovie/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getArticlesStats();
          this.getServerState();
        });
    },
    manualChecking: async function manualChecking() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const delayBetweenRequests = 500; // Délai en millisecondes
      let totalMovies = 0;

      await fetch(this.link + '/allMoviesToCheck', requestOptions)
        .then((res) => res.json())
        .then(async (data) => {
          if (data.msg) return console.log(data.msg);
          console.log(data);
          this.isManualChecking = true;
          this.moviesToScan = data;
          totalMovies = this.moviesToScan.length;

          let currentMovie = 1;
          for (const element of this.moviesToScan) {
            if (!element.link.includes('heavenflix')) {
              await this.sendRequest(element.id, element.name, this.getCodeFromLink(element.link));
              // Ajoutez un délai de 500 ms avant la prochaine itération
              await new Promise((resolve) => setTimeout(resolve, delayBetweenRequests));

              // Affichez une estimation du temps restant
              const remainingTime = (totalMovies - currentMovie) * delayBetweenRequests;
              const minutes = Math.floor(remainingTime / 60000);
              const seconds = ((remainingTime % 60000) / 1000).toFixed(1);
              console.log(`Analyse en cours... ${currentMovie}/${totalMovies} - Temps estimé restant : ${minutes} minutes et ${seconds} secondes`);
              this.remainingTime = `${currentMovie}/${totalMovies} - Temps estimé restant : ${minutes} minutes et ${seconds} secondes`;

              currentMovie++;
            }
          }
        });
      this.remainingTime = 'Analyse terminée.';
      setTimeout(() => {
        this.remainingTime = undefined;
        this.isManualChecking = false;
      }, 5000);
    },
    getCodeFromLink: function getCodeFromLink(link) {
      if (link && link.includes('embed-')) return link.split('embed-')[1].split('.html')[0];
      // Créez un nouvel objet URL
      const urlObject = new URL(link);
      // Accédez à la partie du chemin après le dernier "/"
      const chemin = urlObject.pathname;
      // Divisez le chemin en segments en utilisant "/"
      const segments = chemin.split('/');
      // Le code se trouve dans le dernier segment
      const code = segments[segments.length - 1];
      return code;
    },
    sendRequest: async function sendRequest(id, name, code) {
      try {
        const response = await fetch('https://darkibox.com/embed-' + code + '.html');

        // Vérifiez si la réponse contient le message indiquant que le fichier n'est plus disponible
        if (response.ok) {
          const text = await response.text();
          if (text.includes("File is no longer available as it expired or has been deleted.")) {
            console.log(`Le fichier de ${name} ID : ${id} n'est plus disponible.`);
            this.disabledMoviesFromManualChecking.push(name);
            this.disableMovie(id);
          } else {
            console.log(`Le fichier de ${name} ID : ${id} est disponible.`);
          }
        } else {
          console.log("Erreur de la requête HTTP :", response.status);
        }
      } catch (error) {
        console.error("Erreur lors de la requête HTTP :", error);
      }
    },
    disableAllSeasons: async function disableAllSeasons() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/disableAllSeasons", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getDisabledSeasons();
          this.showNotification('success', 'Toutes les saisons ont été désactivées avec succès.');
          this.getArticlesStats();
          this.getServerState();
        });
    },
    enableAllSeasons: async function enableAllSeasons() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/enableAllSeasons", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getDisabledSeasons();
          this.showNotification('success', 'Toutes les saisons ont été activées avec succès.');
          this.getArticlesStats();
          this.getServerState();
        });
    },
    getDarkiboxInfos: async function getDarkiboxInfos() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(" https://darkibox.com/api/account/info?key=" + this.darkiboxAPIKey, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg == 'Wrong auth' || data.msg == 'Invalid key') return this.showNotification('danger', 'Erreur API Darkibox');
          this.darkiboxInfos = data;
        });
    },
    enableSeasonById: async function enableSeasonById(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/enableSeason/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'La saison a été activée');
          this.getDisabledSeasons();
        }
      });
    },
    editSeasonById: async function editSeasonById(id, season) {
      let links = document.getElementsByClassName('lien-' + id);
      let linksArray = [];
      Array.from(links).forEach(element => {
        linksArray.push(element.value);
      });
      let strArray = JSON.stringify(linksArray);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          liens: strArray,
        })
      };
      await fetch(this.link + '/editSeason/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'La saison ' + season + ' a été éditée');
        }
      });
    },
    getDisabledSeasons: async function getDisabledSeasons() {
      this.seasons = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getDisabledSeasons", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          data.forEach(element => {
            element.episodesLinks = JSON.parse(element.episodesLinks);
            this.seasons.push(element);
          });
        })
    },
    refuseDemande: async function refuseDemande(id, reason) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ reason: reason })
      };
      await fetch(this.link + '/refuseDemande/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', `Demande refusée pour : ${reason}`, 3000);
        this.getAllDemandes();
      });
    },
    validateDemande: async function validateDemande(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/validateDemande/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', 'Demande validée', 3000);
        this.getAllDemandes();
      });
    },
    deleteDemande: async function deleteDemande(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteDemande/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.Error) return this.showNotification('danger', 'Une erreur est survenue', 3000);
        this.showNotification('success', 'Demande supprimée', 3000);
        this.getAllDemandes();
      });
    },
    toggleDisplayDemandes: async function toggleDisplayDemandes() {
      this.showDemandes = !this.showDemandes;
    },
    getAllDemandes: async function getAllDemandes() {
      this.demandes = undefined;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getAllDemandes", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          data.forEach(element => {
            element.date = this.formatDate(element.date);
            if (element.type == 'movie') element.type = 'Film';
            if (element.type == 'serie') element.type = 'Série';
            if (element.status == null) element.status = 'En attente';
          });
          this.demandes = data;
        });
    },
    getArticlesStats: async function getArticlesStats() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getArticlesStats", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.totalEnabledMovies = data.movies.enabled;
          this.totalDisabledMovies = data.movies.disabled;
          this.totalUploadedMovies = data.movies.uploaded;
          this.totalMovies = data.movies.total;
          this.totalEnabledSeries = data.series.enabled;
          this.totalDisabledSeries = data.series.disabled;
          this.totalSeries = data.series.total;
          this.totalEnabledSeasons = data.seasons.enabled;
          this.totalDisabledSeasons = data.seasons.disabled;
          this.totalSeasons = data.seasons.total;
        });
    },
    banUser: async function banUser(id, name) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/ban/" + id + "/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.getAllUsers();
          this.showNotification('success', name + ' a été banni avec succès.')
        });
    },
    unbanUser: async function unbanUser(id, name) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/unban/" + id + "/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.getAllUsers();
          this.showNotification('success', name + ' a été débanni avec succès.')
        });
    },
    setAdmin: async function setAdmin(id, name) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/setAdmin/" + id + "/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.getAllUsers();
          this.showNotification('success', name + ' a été promu avec succès.')
        });
    },
    unsetAdmin: async function unsetAdmin(id, name) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/unsetAdmin/" + id + "/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.getAllUsers();
          this.showNotification('success', name + ' a été rétrogradé avec succès.')
        });
    },
    deleteUser: async function deleteUser(id, name) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/delete/" + id + "/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return this.showNotification('danger', data.msg);
          this.getAllUsers();
          this.showNotification('success', name + ' a été supprimé avec succès.')
        });
    },
    getAllUsers: async function getAllUsers() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getUsers/" + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          data.forEach(element => {
            if (element.email) element.email = 'Oui';
            else element.email = 'Non';
          });
          this.users = data;
        });
    },
    changePosterCollection: async function changePosterCollection(id, name) {
      const input = document.getElementById('collection-' + id + '-input');
      if (!input.value.length) return this.showNotificationWarning('Champs de lien vide!')
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          posterURL: input.value,
          name: name
        })
      };
      await fetch(this.link + "/changePosterCollection/" + id, requestOptions)
        .then((res) => {
          if (res.status === 500) {
            this.showNotification('danger', 'Une erreur s\'est produite lors de la modification du poster. Veuillez réessayer avec une autre image.');
          }
          return res.json();
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getCollections();
          this.showNotification('success', 'Le lien du poster a été modifié avec succès');
        });

    },
    deleteRequest: async function deleteRequest(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteRequest/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.showNotification('success', 'Le signalement a été supprimée avec succès');
          this.getAllRequests();
        });
    },
    redirectToMovie: function redirectToMovie(id) {
      this.$router.push("/player/" + id);
    },
    getAllRequests: async function getAllRequests() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/allRequests", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          this.requests = undefined;
          this.requests = data;
        });
    },
    forceStop: async function forceStop() {
      await fetch(this.link + "/serverStopChecking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          count: 0,
        }),
      })
        .then((response) => response.json())
        .then(() => {
          setTimeout(() => {
            this.getServerState();
          }, 1000);
          this.showNotification('success', 'Le forçage a été effectué avec succès')
        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
          this.showNotification('danger', 'Une erreur s\'est produite lors du forçage.')
        });
    },
    searchDisabledMovie: async function searchDisabledMovie() {
      let input = document.getElementById('search').value;
      if (input.length < 1 || !input) {
        this.getAllFilms();
        return
      }
      this.films = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const moviePromise = await fetch(this.link + '/getDisabledMovieSearch/' + input, requestOptions).then((res) => res.json());
      const movies = await moviePromise;
      movies.forEach(element => {
        element.cat = JSON.parse(element.cat);
        element.cat = element.cat.join(' & ');
        this.films.push(element);
      });
      if (movies.length == 0) this.showNotification('warning', 'Aucun résultat pour cette recherche.')
    },
    startReverseAnimation(target) {
      // Utilisez GSAP pour créer une animation
      gsap.to(target, {
        duration: 0.5,
        opacity: 1, // Animation d'opacité de 1 (complètement visible) à 0 (invisible)
        y: 0, // Animation de la position horizontale
        onStart: () => {
          target.style.display = 'flex';
        },
      });
    },
    startAnimation(target) {
      // Utilisez GSAP pour créer une animation
      gsap.to(target, {
        duration: 0.5,
        opacity: 0, // Animation d'opacité de 1 (complètement visible) à 0 (invisible)
        y: -50, // Animation de la position horizontale
        onComplete: () => {
          if (target) target.style.display = 'none';
        },
      });
    },
    toggleDropdown() {
      this.showOptions = !this.showOptions;
      if (this.showOptions == false) {
        this.startAnimation(this.$refs.myElement);
      } else {
        this.startReverseAnimation(this.$refs.myElement);
      }
    },
    stopFatou: async function stopFatou() {
      await fetch(this.link + "/stopFatou", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(() => {
          setTimeout(() => {
            this.getServerState();
          }, 1000);
          this.showNotification('success', 'Fatou est rentrée à la case!')
        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
        });
    },
    goFatou: async function goFatou() {
      await fetch(this.link + "/goFatou", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(() => {
          if (this.serverState.canWork == false) return this.showNotification('warning', 'Fatou est en RTT, elle ne peut pas travailler!');
          setTimeout(() => {
            this.getServerState();
          }, 1000);
          this.showNotification('success', 'Fatou est partie bosser!')
        })
        .catch((error) => {
          console.error("Une erreur s'est produite :", error);
        });
    },
    fatouCanWork: async function fatouCanWork() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/fatouCanWork", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getServerState();
          this.showNotification('success', 'Fatou est revenue des îles, elle s\'est mise au travail!')
        });
    },
    fatouCantWork: async function fatouCantWork() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/fatouCantWork", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getServerState();
          this.showNotification('success', 'Fatou est partie en RTT dans les îles!')
        });
    },
    enableAllMovies: async function enableAllMovies() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/enableAllMovies", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getAllFilms();
          this.getServerState();
          this.getArticlesStats();
          this.showNotification('success', 'Tous les films ont été activés avec succès')
        });
    },
    enableAllSeries: async function enableAllSeries() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/enableAllSeries", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getAllSeries();
          this.getServerState();
          this.getArticlesStats();
          this.showNotification('success', 'Toutes les séries ont étées activées avec succès')
        });
    },
    disableAllMovies: async function disableAllMovies() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/disableAllMovies", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getAllFilms();
          this.getServerState();
          this.getArticlesStats();
          this.showNotification('success', 'Tous les films ont été désactivés avec succès')
        });
    },
    disableAllSeries: async function disableAllSeries() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/disableAllSeries", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getAllSeries();
          this.getServerState();
          this.getArticlesStats();
          this.showNotification('success', 'Toutes les séries ont étées désactivées avec succès')
        });
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric", // Ajout de l'heure
        minute: "numeric", // Ajout des minutes
        hour12: false,
        timeZone: "Europe/Paris"
      };
      const date = new Date(dateString);
      const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
      return formattedDate;
    },
    async getServerState() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/serverStateChecking", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.serverState = undefined;
          this.serverState = data[0];
        });
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        showIcon: 'true',
        swipeClose: 'true',
        showCloseButton: 'false',
        hideProgressBar: 'false',
        transition: 'slide',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'bottom-right',
        type: 'warning',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationValidation(text) {
      createToast(text, {
        position: 'bottom-right',
        type: 'info',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        toastBackgroundColor: '#2D2E32',
      })
    },
    toggleDisplayCollections: function toggleDisplayCollections() {
      this.showCollections = !this.showCollections;
    },
    toggleDisplayRequests: function toggleDisplayRequests() {
      this.showRequests = !this.showRequests;
    },
    addToCollections: async function addToCollections() {
      const inputCollection = document.querySelector('#inputCollection');
      if (inputCollection.value.length < 2) return this.showNotificationWarning('Champs de collection vide!')
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: inputCollection.value,
        })
      };
      await fetch(this.link + "/addToCollections", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getCollections();
          inputCollection.value = "";
        });
    },
    deleteCollections: async function deleteCollections(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteCollection/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.getCollections();
        });
    },
    getCollections: async function getCollections() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getCollections", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.collections = undefined;
          this.collections = data;
        });
    },
    deleteMovie: async function deleteMovie(value) {
      let card = document.getElementById("card-" + value);
      let text = "Etes vous sur de vouloir supprimer ce film ?";
      if (confirm(text) == false)
        return this.showNotificationWarning('Annulation de la suppression du film')
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteMovie/" + value, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          card.style.display = "none";
          this.getArticlesStats();
        });
    },
    activeSerie: async function activeSerie(id) {
      let card = document.getElementById("card-serie-" + id);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/enableSerie/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.showNotification('success', 'La série a été activée avec succès')
          card.style.display = "none";
          this.getArticlesStats();
        });
    },
    editArticle: async function editArticle(value, type) {
      this.$router.push({
        name: 'edit',
        params: {
          type: type,
          id: value,
        }
      });
    },
    enableMovieNoModification: async function enableMovieNoModification(value, name) {
      let card = document.getElementById("card-" + value);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/enableMovie/" + value, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          card.style.display = "none";
          this.showNotification('success', 'Le film ' + name + ' a été activé sans motifications');
          this.getServerState();
          this.getArticlesStats();
        });
    },
    changeStreamingLink: async function changeStreamingLink(id, name) {
      let card = document.getElementById("card-" + id);
      let input = document.getElementById("input-" + id);
      if (input.value.length < 2) {
        this.enableMovieNoModification(id, name);
        return
      }
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          link: input.value,
        }),
      };
      await fetch(this.link + "/editMovieLink/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          card.style.display = "none";
          this.showNotification('success', 'Lien du film ' + name + ' a été modifié avec succès')
          this.getServerState();
          this.getArticlesStats();
        });
    },
    getAllFilms: async function getAllFilms() {
      this.films = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/allMoviesDisable", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          data.forEach(element => {
            this.films.push(element)
          })
        });
    },
    getAllSeries: async function getAllSeries() {
      this.getAllSeries = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/allSeriesDisable", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          data.forEach(element => {
            this.series.push(element)
          })
        });
    },
    getDownloads: async function getDownloads() {
      this.downloads = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/downloads", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.downloads = data;
        });
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push("/home");
        });
    },
  },
  created: function () {
    this.getServerState();
    this.getAllFilms();
    this.getAllSeries();
    this.admin();
    this.getCollections();
    this.getAllRequests();
    this.getAllUsers();
    this.getArticlesStats();
    this.getAllDemandes();
    this.getDisabledSeasons();
    this.getDarkiboxInfos();
    this.getDownloads();
    AOS.init();
  },
  mounted: function () {
    AOS.init();
    setTimeout(() => {
      this.startAnimation(this.$refs.myElement);
    }, 500);
  }
};
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

* {
  padding: 0;
  margin: 0;
}

@keyframes slidedown {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $dark-color;

  .noResult {
    margin: auto;
    width: 70%;

    h1 {
      color: white;
      font-size: 22px;
      font-family: "Montserrat";
      margin: 40px;
    }
  }

  .manualCheckerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .manualChecker {
      width: 750px;
      background: $soft-grey;
      padding: 20px 20px;
      border-radius: 15px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      max-height: 500px;
      overflow-x: hidden;

      h2 {
        font-family: "Montserrat";
        font-size: 35px;
        text-align: center;
      }

      p {
        font-family: "Montserrat";
        font-size: 16px;
        text-align: center;

      }

      .disabledMovieContainer {
        padding: 10px;
        background: $dark-color;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: start;
        border-radius: 15px;
        overflow-y: auto;

        p {
          font-family: "Montserrat";
          font-size: 16px;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .serverContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.5s;

    .server {
      transition-duration: 0.5s;
      width: 80%;
      background: $soft-grey;
      margin-top: 35px;
      padding: 20px 20px;
      border-radius: 15px;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .legende {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        font-family: 'Lato';
        font-size: 14px;
        padding: 10px 0 0 0;

        span {
          p {
            font-weight: bold;

            span {
              font-size: 20px;
            }
          }
        }
      }

      .showOptionsButton {
        background: $back-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        border-radius: 10px;
        margin-top: 10px;
        z-index: 2;

        &:hover {
          cursor: pointer;
        }
      }

      .options {
        z-index: 1;
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        background: $back-color;
        padding: 20px 0;
        border-radius: 10px;

        .requestDone {
          background: $soft-grey;
          color: white;
        }

        button {
          border: none;
          padding: 10px 15px;
          background: $dark-red;
          color: white;
          font-family: "Montserrat";
          font-size: 14px;
          border-radius: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .serverTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-family: 'Lato';

        .serverIcon {

          span {
            font-size: 30px;
          }
        }
      }

      .statsTable {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        font-family: 'Lato';
        font-size: 14px;
        padding: 10px 0 0 0;

        overflow: hidden;
        overflow-x: auto;

        table {
          width: 100%;
          background: $dark-color;
          padding: 20px 20px;
          border-radius: 15px;
          color: white;
          overflow-x: auto;

          thead {
            tr {
              th {
                font-family: 'Montserrat';
                font-size: 18px;
                padding: 10px 0 10px 5px;
                text-align: center;
              }
            }

            background: $dark-color;
          }

          tbody {
            tr {
              td {
                font-family: 'Montserrat';
                font-size: 16px;
                padding: 7px 0;
                text-align: center;
              }

              &:nth-child(odd) {
                background: rgba(255, 255, 255, 0.1);
              }

              button {
                border: none;
                padding: 5px 15px;
                background: $dark-red;
                color: white;
                font-family: "Montserrat";
                font-size: 14px;
                border-radius: 10px;

                &:hover {
                  cursor: pointer;
                }

              }

              .tableButtonDelete {
                background: $dark-red;
                color: $light-color;
              }

              .tableButtonPromotion {
                background: #ffc300;
                color: $dark-color;
                font-weight: bold;

              }

              .tableButtonDowngrade {
                background: #023047;
                color: $light-color;
              }

              .tableButtonBan {
                background: #023047;
                color: $light-color;
              }

              .tableButtonUnban {
                background: #f1c0e8;
                color: $dark-color;
              }
            }
          }
        }
      }

      p {
        font-family: 'Montserrat';
        font-size: 16px;

        span {
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }

  .collectionDisplayerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px 0 5px 0;

    .collectionDisplayerButton {
      width: 80%;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $soft-grey;
      padding: 20px 20px;
      border-radius: 15px;
      transition-duration: 0.4s;
      z-index: 2;

      &:hover {
        cursor: pointer;
        transition-duration: 0.4s;
        //background-color: $dark-red;
      }

      p {
        font-size: 20px;
        font-family: "Montserrat";
      }

      input {
        border: none;
        padding: 15px 15px;
        border-radius: 10px;
        width: 95%;
        outline: none;
        background: $dark-color;
        color: $light-color;
      }

      button {
        border: none;
        padding: 10px 15px;
        background: color;
        font-family: "Montserrat";
        font-size: 14px;
        border-radius: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .requestAddContainer {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: $soft-grey;
    border-radius: 15px;
    gap: 20px;
    padding: 10px 20px 10px 20px;
    animation: slidedown 0.5s forwards;
    z-index: 1;
    overflow: hidden;
    overflow-x: auto;

    table {
      width: 100%;
      background: $dark-color;
      padding: 20px 20px;
      border-radius: 15px;
      color: white;
      overflow-x: auto;

      thead {
        tr {
          th {
            font-family: 'Montserrat';
            font-size: 18px;
            padding: 10px 0 10px 5px;
            text-align: center;
          }
        }

        background: $dark-color;
      }

      tbody {
        tr {
          td {
            font-family: 'Montserrat';
            font-size: 16px;
            padding: 7px 0;
            text-align: center;
            min-height: 50px;
            background: rgba(255, 255, 255, 0.1);
          }

          .tableActions {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            height: 100%;
            padding: 10px;

            a {
              text-decoration: none;
              color: $dark-color;
              font-family: 'Lato';
              font-size: 14px;
              padding: 5px 15px;
              border-radius: 10px;
              background: $light-color;

              &:hover {
                cursor: pointer;
              }
            }

            button {
              border: none;
              padding: 5px 15px;
              background: #ffc300;
              color: $dark-color;
              font-weight: bold;
              font-family: "Montserrat";
              font-size: 14px;
              border-radius: 10px;

              &:first-child {
                background: $light-color;
                color: $dark-color;
              }

              &:last-child {
                background: $dark-red;
                color: $light-color;
              }

              &:hover {
                cursor: pointer;
              }

              &:disabled {
                background: $dark-color;
                color: rgb(58, 58, 58);
              }

            }
          }
        }
      }
    }

    p {
      font-size: 16px;
      color: white;
      font-family: 'Montserrat';
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 5px;

      li {
        display: flex;
        color: white;
        font-family: 'Montserrat';
        justify-content: space-between;

        .options {
          display: flex;
          align-items: center;
          gap: 10px;

          a {
            text-decoration: none;
            color: $dark-color;
            font-family: 'Lato';
            font-size: 14px;
            padding: 5px 15px;
            border-radius: 10px;
            background: $light-color;

            &:hover {
              cursor: pointer;
            }
          }

          button {
            border: none;
            padding: 5px 15px;
            background: $dark-red;
            color: white;
            font-family: "Montserrat";
            font-size: 14px;
            border-radius: 10px;

            &:hover {
              cursor: pointer;
            }

            &:first-child {
              background: $light-color;
              color: $dark-color;
              font-weight: bold;
            }
          }
        }

        p {
          display: flex;
          justify-content: start;
          align-items: center;
          font-size: 16px;
        }
      }
    }
  }

  .collectionAddContainer {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: $soft-grey;
    border-radius: 15px;
    gap: 20px;
    padding: 10px 20px 30px 20px;
    animation: slidedown 0.5s forwards;
    z-index: 1;

    h2 {
      padding: 10px;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-family: "Montserrat";
    }

    .collectionAdd {
      input {
        border: none;
        padding: 5px 15px;
        border-radius: 5px;
        max-width: 300px;
      }

      button {
        cursor: pointer;
        margin-left: 5px;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        background: $dark-red;
      }
    }

    .collectionsContainer {
      z-index: 1;
      width: 100%;
      animation: slidedown 0.5s forwards;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
      padding: 10px 5px 30px 5px;

      ul {
        list-style: none;
        column-count: 2;
        width: 100%;

        li {
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          transition-duration: 0.4s;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.2);
            transition-duration: 0.4s;
          }

          .collectionsOptions {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          p {
            font-family: "Montserrat";
            font-size: 16px;
          }

          input {
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            max-width: 300px;
          }

          button {
            cursor: pointer;
            background: $dark-red;
            border: none;
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }
      }

      .collection {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px;

        p {
          padding: 10px;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-family: "Montserrat";
        }

        button {
          cursor: pointer;
          background: $dark-red;
          border: none;
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
        }
      }

    }
  }

  .cardContainer {
    margin: auto;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding: 75px 0 60px 0;

    .seasonCard {
      width: 100%;
      max-width: 100%;
      height: auto;
      animation: appear 1s forwards;
      background: $soft-grey;
      border-radius: 15px;
      padding: 0 25px 50px 25px;

      .seasonCardTitle {
        display: flex;
        align-items: center;
        padding: 10px 0 0 0;
        gap: 5px;

        h2 {
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-family: "Montserrat";
        }

        p {
          color: rgba(255, 255, 255, 0.7);
          font-size: 18px;
          font-family: "Montserrat";
        }
      }

      .seasonCardRedirects {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;
        width: 100%;

        a {
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-family: "Montserrat";
          text-decoration: none;
          transition-duration: 0.4s;
          background: $dark-color;
          padding: 5px 10px;
          border-radius: 5px;

          &:hover {
            transition-duration: 0.4s;
            color: $dark-red;
          }
        }
      }

      .seasonCardInteractions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        padding: 10px 0;

        button {
          border: none;
          padding: 10px 15px;
          background: $dark-red;
          color: white;
          font-family: "Montserrat";
          font-size: 14px;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .seasonCardEpisodeInput {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 90%;
        max-width: 90%;
        margin-top: 10px;

        label {
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          font-family: "Montserrat";
        }

        input {
          width: 100%;
          max-width: 100%;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
        }
      }
    }

    .card {
      width: 20%;
      height: auto;
      animation: appear 1s forwards;
      background: transparent;
      width: 275px;
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      font-family: "Lato";
      animation: slide 2s;
      border-radius: 15px;
      color: white;
      margin: 20px;
      animation: appear 1s forwards;

      .interactions {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        button {
          width: fit-content;
          border: none;
          padding: 10px 15px;
          background: $dark-red;
          color: white;
          margin: 10px 0;
          font-family: "Montserrat";
          font-size: 14px;
          border-radius: 5px;

          &:last-child {
            background: transparent;
            border-bottom: 2px solid $dark-red;
            transition-duration: 0.4s;

            &:hover {
              transition-duration: 0.4s;
              cursor: pointer;
              background: $dark-red;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .year {
        display: flex;
        gap: 5px;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
        margin: 3px 0 10px 0;
      }

      img {
        width: 100%;
        height: 410px;
        object-fit: cover;
        image-rendering: auto;
        border-radius: 15px;
        box-shadow: 4px 4px 17px 5px rgba(0, 0, 0, 0.72);
      }

      a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 10px 0 0 0;
        color: white;
        text-decoration: none;
        font-weight: bold;
        font-size: 20px;
      }

      input {
        margin: 10px 0 0 0;
        padding: 10px 15px;
        border: none;
        outline: none;
        border-radius: 10px;
      }

      label {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {

    .collectionDisplayerContainer {
      .collectionDisplayerButton {
        width: 80%;
      }
    }

    .collectionAddContainer {
      width: 80%;
    }

    .collectionsContainer {
      width: 80%;

      ul {
        column-count: 1;
      }
    }

    .requestAddContainer {
      width: 80%;

      ul {
        li {
          p {
            font-size: 14px;
            width: 50%;
          }
        }
      }

      .options {
        flex-direction: column;
        gap: 5px;

        button {
          font-size: 14px;
        }
      }
    }

    .serverContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .server {
        width: 80%;
        background: $soft-grey;
        margin-top: 35px;
        padding: 20px 20px;
        border-radius: 15px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .statsTable {
          overflow: hidden;
          overflow-x: auto;
        }

        .options {
          justify-content: flex-start;

          button {
            margin-left: 10px;
          }
        }

        .serverTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          font-family: 'Lato';

          .serverIcon {

            span {
              font-size: 30px;
            }
          }
        }

        p {
          font-family: 'Montserrat';
          font-size: 16px;

          span {
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
