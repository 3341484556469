<template>
    <div class="card" :id="'card-' + movie.id">
        <img :src="movie.backgroundURL" alt="Affiche du film"
            @error="this.src = 'https\://api.heavenflix.fr/images/error.webp'">
        <div class="cardLogos" v-if="movie.quality || movie.isCanadian">
            <div class="highQuality" v-if="movie.quality">
                <p v-if="movie.quality == '4K'">4K</p>
                <p v-else-if="movie.quality != '1080p' && movie.quality != '4K' && movie.quality != '720p'">SD</p>
            </div>
            <div class="canadian" v-if="movie.isCanadian">
                <i class="fa-brands fa-canadian-maple-leaf"></i>
            </div>
        </div>
        <div class="bigZone" v-if="type == 'movie'">
            <div class="pres">
                <h4 class="titles">{{ movie.name }} | <span>{{ movie.annedesortie }}</span></h4>
                <span class="categories" v-if="movie.cat">{{ movie.cat }}</span>
            </div>
            <div class="duration">
                <span><i class="fa-regular fa-clock"></i> {{ movie.duration }}</span>
            </div>
        </div>
        <div class="bigZone" v-else-if="type == 'serie'">
            <div class="pres">
                <h4 class="titles">{{ movie.name }} | <span v-if="movie.anneedesortie != movie.annedefin">{{
        movie.anneedesortie }} - {{ movie.annedefin }}</span><span v-else>{{ movie.anneedesortie
                        }}</span>
                </h4>
                <span class="categories" v-if="movie.categories">{{ movie.categories }}</span>
            </div>

        </div>
        <div class="bigZone" v-else-if="type == 'list'">
            <div class="pres">
                <h4 class="titles">{{ movie.name }} | <span>{{ movie.annedesortie }}</span></h4>
                <span class="categories" v-if="movie.cat">{{ movie.cat }}</span>
            </div>
            <div class="duration">
                <span><i class="fa-regular fa-clock"></i> {{ movie.duration }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from 'aos';

export default {
    name: 'CardMovieContainer',
    props: {
        movie: Object,
        type: String,
    },
    data() {
        return {

        }
    },
    methods: {
        isOwnHosted(link) {
            if (link.includes('heavenflix')) {
                return true
            }
        }
    },
    created() {
        // Your created hook code here
        AOS.init;
    },
    mounted() {
        // Your mounted hook code here
    }
}
</script>

<style scoped lang="scss">
$dark-color: #1b1b1b;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;
$card-width: 400px;

.card {
    position: relative;
    background: transparent;
    width: $card-width;
    //width: 275px;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-family: 'Lato';
    font-display: swap;
    border-radius: 15px;
    color: white;
    margin: 20px;

    &:hover {
        transition-duration: 0.3s;
        cursor: pointer;

        img {
            filter: brightness(0.5);
        }
    }

    img {
        width: 100%;
        height: 220px;
        //height: 410px;
        object-fit: cover;
        //image-rendering: auto;
        border-radius: 10px 10px 0 0;
        box-shadow: 4px 4px 17px 5px rgba(0, 0, 0, 0.72);
        transition-duration: 0.3s;

    }

    .cardLogos {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        .trailer {
            svg {
                width: 30px;
                height: 30px;
                fill: $dark-red;
                z-index: 1;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                padding: 2px;
                backdrop-filter: blur(5px);
            }
        }

        .hosted {
            svg {
                width: 30px;
                height: 30px;
                fill: $dark-red;
                z-index: 1;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                padding: 2px;
                backdrop-filter: blur(5px);

            }
        }

        .canadian {
                
                i {
                    display: flex;
                justify-content: center;
                align-items: center;
                width: 23px;
                height: 23px;
                    font-size: 25px;
                    color: $dark-red;
                    background: rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                padding: 6px;
                backdrop-filter: blur(5px);
                }
        }

        .highQuality {

            p {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 23px;
                height: 23px;
                font-family: 'Montserrat';
                font-size: 15px;
                font-weight: bold;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                padding: 6px;
                backdrop-filter: blur(5px);
            }
        }
    }

    .bigZone {
        //margin-top: 10px;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: #13151d;
        border-radius: 0 0px 10px 10px;

        .pres {
            padding: 10px;
            max-width: 70%;
            height: fit-content;
            display: flex;
            flex-direction: column;

            h4 {
                max-width: 300px;
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Open Sans';
            }

            span {
                font-family: 'Montserrat';
                font-display: swap;
                font-size: 15px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgba(255, 255, 255, 0.61);
            }
        }

        .duration {
            width: 5%;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            margin-right: 25px;

            span {
                font-family: 'Montserrat';
                font-size: 15px;
                color: rgba(255, 255, 255, 0.61);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }
        }
    }

    .interactions {
        width: fit-content;
        display: flex;
        justify-content: space-between;

        button {
            width: auto;
            display: flex;
            align-items: center;
            background: transparent;
            border: none;

            &:hover {
                cursor: pointer;
            }

            i {
                color: $dark-red;
                font-size: 25px;
            }
        }

        .options {
            margin: 0 10px;
            font-size: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            .heart {
                width: fit-content;
                position: relative;
                transition-duration: 1s;
                color: $dark-red;

                &:hover {
                    cursor: pointer;
                    transition-duration: 1s;

                    i {
                        color: white;
                        transition-duration: 1s;
                    }
                }
            }
        }

        .resume {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }
}

//media query tablette + 1px (pour éviter les bugs)
@media screen and (max-width: 1025px) {
    .card {
        min-width: 25%;
        margin: 20px;

        img {
            width: 100%;
            max-height: 410px;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}

@media screen and (max-width: 769px) {
    .card {
        min-width: 450px;
        margin: 20px;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}

@media screen and (max-width: 426px) {
    .card {
        min-width: 300px;
        margin: 20px;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}
</style>
