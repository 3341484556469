<template>
  <div class="homePage" id="home">
    <div class="random" v-if="randomFilm && randomFilm.length > 0">
      <div class="randomContainer" v-for="element in randomFilm" v-bind:key="element.id">
        <img :src="element.backgroundURL" />
        <div class="poster" data-aos="fade-right" data-aos-anchor-placement="center-bottom" data-aos-duration="500">
          <img :src="element.posterURL" @click="seenSerie(element.id)" loading="lazy" alt="Poster de la série" @error="
            this.src = 'https\://api.heavenflix.fr/images/error.webp'
            " />
        </div>
        <div class="titleSeasons">
          <p>
            {{ element.name }} |<span v-if="element.seasonsCount && element.seasonsCount > 1">{{ element.seasonsCount }}
              saisons disponibles</span>
            <span v-if="element.seasonsCount && element.seasonsCount == 1">{{ element.seasonsCount }} saison
              disponible</span>
          </p>
        </div>
      </div>
    </div>
    <div class="noFilmContainer" v-else>
      <p>Aucun résultat..</p>
    </div>
    <NavBar />
    <div class="goTop" v-show="scrollDistance > 775">
      <button :onclick="() => goTop()" title="Remonter" id="topButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-chevrons-up">
          <polyline points="17 11 12 6 7 11"></polyline>
          <polyline points="17 18 12 13 7 18"></polyline>
        </svg>
      </button>
    </div>
    <div class="displayer" id="displayer">
      <div class="displayerContainer" v-for="element in selectedSerie" v-bind:key="element.id" data-aos="fade-in"
        data-aos-duration="2000">
        <div class="exit">
          <button :onclick="() => closeSeenSerie()" title="Fermer l'aperçu">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="upper">
          <img class="back" :src="element.backgroundURL" />
          <img :src="element.posterURL" />
          <div class="section">
            <div v-if="isAdmin > 0">
              <button :onclick="() => disableSerie(element.id)" title="Désactiver">
                <i class="fa-solid fa-square-xmark"></i>
              </button>
            </div>
            <div v-if="isAdmin > 0">
              <button :onclick="() => editSerie(element.id)" title="Éditer">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
            </div>
            <div v-if="isAdmin > 0">
              <button :onclick="() => deleteSerie(element.id)" title="Supprimer">
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="lower">
          <h3>{{ element.name }}</h3>
          <div class="cat">
            <p>{{ element.categories }}</p>
          </div>
          <p class="randomDuration" v-if="element.anneedesortie != element.annedefin">
            <a class="randomDuration">
              <i class="fa-regular fa-calendar-days"></i>
              {{ element.anneedesortie }} - {{ element.annedefin }}</a>
          </p>
          <p class="randomDuration" v-else>
            <a class="randomDuration"><i class="fa-regular fa-calendar-days"></i>
              {{ element.anneedesortie }}</a>
          </p>
          <p>{{ element.description }}</p>
          <div class="movieInfosContainer" v-if="infos">
            <div class="movieInfos">
              <a :href="`https://www.imdb.com/title/${infos.imdbID}/`" target="_blank"><svg id="home_img"
                  class="ipc-logo" xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32"
                  version="1.1">
                  <g fill="#F5C518">
                    <rect x="0" y="0" width="100%" height="100%" rx="4"></rect>
                  </g>
                  <g transform="translate(8.000000, 7.000000)" fill="#000000" fill-rule="nonzero">
                    <polygon points="0 18 5 18 5 0 0 0"></polygon>
                    <path
                      d="M15.6725178,0 L14.5534833,8.40846934 L13.8582008,3.83502426 C13.65661,2.37009263 13.4632474,1.09175121 13.278113,0 L7,0 L7,18 L11.2416347,18 L11.2580911,6.11380679 L13.0436094,18 L16.0633571,18 L17.7583653,5.8517865 L17.7707076,18 L22,18 L22,0 L15.6725178,0 Z">
                    </path>
                    <path
                      d="M24,18 L24,0 L31.8045586,0 C33.5693522,0 35,1.41994415 35,3.17660424 L35,14.8233958 C35,16.5777858 33.5716617,18 31.8045586,18 L24,18 Z M29.8322479,3.2395236 C29.6339219,3.13233348 29.2545158,3.08072342 28.7026524,3.08072342 L28.7026524,14.8914865 C29.4312846,14.8914865 29.8796736,14.7604764 30.0478195,14.4865461 C30.2159654,14.2165858 30.3021941,13.486105 30.3021941,12.2871637 L30.3021941,5.3078959 C30.3021941,4.49404499 30.272014,3.97397442 30.2159654,3.74371416 C30.1599168,3.5134539 30.0348852,3.34671372 29.8322479,3.2395236 Z">
                    </path>
                    <path
                      d="M44.4299079,4.50685823 L44.749518,4.50685823 C46.5447098,4.50685823 48,5.91267586 48,7.64486762 L48,14.8619906 C48,16.5950653 46.5451816,18 44.749518,18 L44.4299079,18 C43.3314617,18 42.3602746,17.4736618 41.7718697,16.6682739 L41.4838962,17.7687785 L37,17.7687785 L37,0 L41.7843263,0 L41.7843263,5.78053556 C42.4024982,5.01015739 43.3551514,4.50685823 44.4299079,4.50685823 Z M43.4055679,13.2842155 L43.4055679,9.01907814 C43.4055679,8.31433946 43.3603268,7.85185468 43.2660746,7.63896485 C43.1718224,7.42607505 42.7955881,7.2893916 42.5316822,7.2893916 C42.267776,7.2893916 41.8607934,7.40047379 41.7816216,7.58767002 L41.7816216,9.01907814 L41.7816216,13.4207851 L41.7816216,14.8074788 C41.8721037,15.0130276 42.2602358,15.1274059 42.5316822,15.1274059 C42.8031285,15.1274059 43.1982131,15.0166981 43.281155,14.8074788 C43.3640968,14.5982595 43.4055679,14.0880581 43.4055679,13.2842155 Z">
                    </path>
                  </g>
                </svg></a>
              <p v-if="infos && infos.Title">
                Titre international : <span>{{ infos.Title }}</span>
              </p>
              <p v-if="
                infos && infos.totalSeasons && infos.totalSeasons != 'N/A'
              ">
                Nombre de saisons :
                <span v-if="infos.totalSeasons == 1">{{ infos.totalSeasons }} saison</span><span v-else>{{
                  infos.totalSeasons }} saisons</span>
              </p>
              <p v-if="infos && infos.BoxOffice && infos.BoxOffice != 'N/A'">
                Box-Office :
                <span v-if="convertedBoxOffice">{{ convertedBoxOffice }} ({{ infos.BoxOffice }})</span>
                <span v-else>
                  {{ infos.BoxOffice }}
                </span>
              </p>
              <p v-if="infos && infos.Production && infos.Production != 'N/A'">
                Production : <span>{{ infos.Production }}</span>
              </p>
              <p v-if="infos && infos.Director && infos.Director != 'N/A'">
                Réalisateur(s) : <span>{{ infos.Director }}</span>
              </p>
              <p v-if="infos && infos.Writer && infos.Writer != 'N/A'">
                Scénariste(s) : <span>{{ infos.Writer }}</span>
              </p>
              <p v-if="infos && infos.Actors">
                Acteur(s) : <span>{{ infos.Actors }}</span>
              </p>
              <p v-if="infos && infos.Ratings && infos.Ratings.length > 0">
                Evaluation(s) :
              </p>
            </div>
            <div class="rating" v-if="infos && infos.Ratings && infos.Ratings.length > 0">
              <div class="ratingCard" v-for="element in infos.Ratings" v-bind:key="element">
                <div v-if="element.Source == 'Internet Movie Database'">
                  <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/171_Imdb_logo_logos-512.png"
                    :alt="'logo de ' + element.Source" loading="lazy" />
                </div>
                <div v-else-if="element.Source == 'Rotten Tomatoes'">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Rotten_Tomatoes.svg/1200px-Rotten_Tomatoes.svg.png"
                    :alt="'logo de ' + element.Source" loading="lazy" />
                </div>
                <div v-else-if="element.Source == 'Metacritic'">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Metacritic.svg/1200px-Metacritic.svg.png"
                    :alt="'logo de ' + element.Source" loading="lazy" />
                </div>
                <p>{{ element.Source }}</p>
                <span>{{ element.Value }}</span>
              </div>
            </div>
          </div>
          <!--<div class="lastEpisode" v-if="lastEpisodeMessage">
            <p>{{ lastEpisodeMessage }}</p>
          </div>-->
          <select @change="selectSeason()" class="seasonSelector" id="seasonSelector"
            v-if="uniqueSeasons && uniqueSeasons.length > 0">
            <option v-for="season in uniqueSeasons" :key="season" :value="season">
              Saison {{ season }}
            </option>
          </select>
          <div class="episodes" v-if="selectedNewEpisodes && selectedNewEpisodes.length > 0">
            <div class="episode" v-for="episode in selectedNewEpisodes" :key="episode.id">
              <div :class="episode.duration ? 'episodePoster' : 'episodePoster disabled'" @click="episode.duration ? playSerie(episode.link,episode.episode_number,episode.serie_id,episode.season) : null">
                <img :src="episode.episode_poster_url" alt="Poster de l'épisode" loading="lazy" />
                <div class="watchingTime" :style="{
                  width: getDurationPercent(
                    checkIfEpisodeIsWatched(episode.link),
                    episode.duration
                  ),
                  height: '5px',
                  'z-index': 3,
                }" v-if="checkIfEpisodeIsWatched(episode.link)"></div>
              </div>
              <div class="episodeInfos">
                <p>
                  <b>{{ episode.episode_number }}. </b>{{ episode.title }}
                  <span class="duration" v-if="episode.duration">{{
                    convertDuration(episode.duration)
                  }}</span><span class="duration" v-else-if="!episode.duration">Indisponible</span>
                </p>
                <p class="description">{{ episode.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NotificationsContainer />
    <div class="order" id="top">
      <select name="triage" id="triage" @change="sortSeries()">
        <option selected>Trier</option>
        <option value="name.asc">De A à Z</option>
        <option value="name.desc">De Z à A</option>
        <option value="id.desc">Les derniers ajouts</option>
        <option value="id.asc">Les premiers ajouts</option>
        <option value="anneedesortie.desc">Les plus récentes</option>
        <option value="anneedesortie.asc">Les plus anciennes</option>
      </select>
      <select name="category" id="categories" :onchange="() => getAllByCategory()">
        <option value="" selected>Catégorie</option>
        <option v-bind:key="element.id" v-for="element in categories" :value="element.name">
          {{ element.name }}
        </option>
      </select>
      <div class="searchBarContainer">
        <div class="searchBar">
          <input type="search" id="searchBar" placeholder="Rechercher.." />
          <svg :onclick="() => search()" alt="Icone de recherche" xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
        </div>
      </div>
    </div>
    <div class="noResult" v-if="!series.length">
      <p>Aucun résultat..</p>
    </div>
    <swiper class="watchedSection" v-if="watchtimes_episodes && watchtimes_episodes.length > 0" :navigation="false"
      :modules="modules" id="swiperWatched" slidesPerView="auto" spaceBetween="40px">
      <swiper-slide v-for="item in watchtimes_episodes" v-bind:key="item.id" class="watchedSlider">
        <div class="watchedCard" v-if="item.watchtime">
          <div class="delete" @click="deleteWatchtime(item)">
            <i class="fa-solid fa-trash"></i>
          </div>
          <img :src="item.episode_poster_url" alt="Poster de la série" loading="lazy" />
          <div class="watchedInfos">
            <div class="watchingTime" :style="{
              width: getDurationPercent(item.watchtime, item.duration),
              height: '5px',
              'z-index': 3,
            }"></div>
            <p class="name">
              {{ item.serie_name }} - {{ item.title }} (S{{item.season}}E{{
                item.episode_number
              }})
            </p>
            <button @click="
              playSerie(
                item.link,
                item.episode_number,
                item.serie_id,
                item.season
              )
              ">
              <i class="fa-solid fa-circle-play"></i> Reprendre
            </button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="filmsSection" v-if="series && series.length && wantWideCards == true">
      <CardMovieContainer v-for="item in series.slice(0, limit)" v-bind:key="item.id" :id="'card-' + item.id"
        data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" :movie="item" :type="'serie'"
        @click="seenSerie(item.id)" />
    </div>
    <div class="filmsSection" v-else-if="series && series.length && wantWideCards == false">
      <CardMoviePosterContainer v-for="item in series.slice(0, limit)" v-bind:key="item.id" :movie="item"
        data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak
        @click="seenSerie(item.id)" :type="'serie'" />
    </div>
    <div class="loaderWrapper">
      <div class="loader" v-if="limit < series.length">
        <button class="loaderBtn" :onclick="() => loadMore()">
          Charger d'avantage <i class="fa-solid fa-arrow-turn-down"></i>
        </button>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
import AOS from "aos";
import NavBar from "./NavBarContainer.vue";
import CardMovieContainer from "./CardMovieContainer.vue";
import CardMoviePosterContainer from "./CardMoviePosterContainer.vue";
import FooterContainer from "./FooterContainer.vue";
import NotificationsContainer from "./NotificationsContainer.vue";
//SwiperJS
import { Swiper, SwiperSlide } from "swiper/vue";
// importez les modules Swiper requis
import { Navigation, Pagination, Zoom, Virtual } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "SeriesContainer",
  data() {
    return {
      selectedSerie: undefined,
      selectedSeason: 1,
      series: [],
      categories: undefined,
      randomFilm: undefined,
      isAdmin: 0,
      limit: localStorage.getItem("limit"),
      link: "https://api.heavenflix.fr",
      codes: [],
      scrollDistance: 0,
      wantWideCards:
        JSON.parse(localStorage.getItem("wantWideCards")) == true
          ? true
          : false,
      infos: undefined,
      convertedBoxOffice: undefined,
      exchangeRateApiKey: "380c6ec2439ac58f95465c2f",
      exchangeRateApiLink:
        "https://v6.exchangerate-api.com/v6/380c6ec2439ac58f95465c2f/latest/USD",
      newSeasons: undefined,
      selectedNewEpisodes: undefined,
      uniqueSeasons: undefined,
      watchtimes_episodes: undefined,
    };
  },
  components: {
    NavBar,
    CardMovieContainer,
    CardMoviePosterContainer,
    FooterContainer,
    NotificationsContainer,
    Swiper,
    SwiperSlide,
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const onSwiper = (Swiper) => { };

    // eslint-disable-next-line no-unused-vars
    const onSlideChange = (swiper) => {
      /*const slides = document.getElementsByClassName('swiper-slide');
      let currentIndex = swiper.activeIndex;*/
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Zoom, Virtual],
    };
  },
  methods: {
    convertDuration: function convertDuration(duration) {
      // Convertir la durée en minutes
      const minutes = Math.floor(duration / 60);

      // Renvoyer la durée en minutes en tant que chaîne
      return minutes.toString() + "min";
    },
    checkIfEpisodeIsWatched(link) {
      //Vérifier par le lien si l'épisode a été regardé même partiellement et renvoyer la durée regardée et non juste un booléen
      let watchtime = this.watchtimes_episodes.find(
        (episode) => episode.link == link
      );
      if (watchtime) return watchtime.watchtime;
      return false;
    },
    deleteWatchtime: async function deleteWatchtime(watchtime) {
      const folder = watchtime.video_path.split("/")[5];
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/watchtimeSerie/" + folder + '/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (!data || !data.code || data.code != 200)
            return console.log("Erreur lors de la suppression du watchtime");
          this.getWatchedEpisodes();
        });
    },
    //Calculer le pourcentage entre le watchedTime et la durée du film
    getDurationPercent(watchtime, duration) {
      if (!watchtime || !duration) {
        return "0%"; // Ajoutez une unité à la valeur retournée
      }
      let percent = (watchtime / duration) * 100;
      // Réduire le pourcentage à un INT pour éviter les problèmes d'affichage
      percent = Math.round(percent);
      return percent + "%"; // Ajoutez une unité à la valeur retournée
    },
    goToSeriePlayer: function goToSeriePlayer(id) {
      this.$router.push({ name: "player", params: { type: "serie", id: id } });
    },
    getWatchedEpisodes: async function getWatchedEpisodes() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      await fetch(
        this.link + "/watchtime/episodes/" + localStorage.getItem("userId"),
        requestOptions
      )
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          this.watchtimes_episodes = undefined;
          this.watchtimes_episodes = data;
        });
    },
    // Fonction pour convertir des dollars en euros
    convertUSDToEUR: async function convertUSDToEUR(amountUSD) {
      try {
        // Supprimer le signe dollar et convertir le montant en entier
        const amountInteger = parseInt(
          amountUSD.replace("$", "").replace(/,/g, "")
        );

        // Récupérer les taux de change actuels
        const response = await fetch(this.exchangeRateApiLink);
        const data = await response.json();

        // Vérifier si la requête a réussi
        if (response.ok) {
          // Obtenir le taux de change USD vers EUR
          const usdToEurRate = data.conversion_rates.EUR;

          // Convertir le montant de dollars en euros
          const amountEUR = amountInteger * usdToEurRate;

          // Arrondir le montant vers le bas pour retirer les centimes
          const roundedAmountEUR = Math.floor(amountEUR);

          // Formater le montant avec des virgules pour les milliers et sans décimales
          const formattedAmountEUR = roundedAmountEUR.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          });

          this.convertedBoxOffice = formattedAmountEUR + "€";
        } else {
          console.error("Erreur lors de la récupération des taux de change");
          return null;
        }
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la conversion:",
          error
        );
        return null;
      }
    },
    getOMDBInfos: async function getOMDBInfos(imdb_id) {
      if (!imdb_id) return;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(
        this.link + "/getToOMBD/" + imdb_id + "/serie",
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.infos = data;
          if (this.infos.BoxOffice && this.infos.BoxOffice != "N/A")
            this.convertUSDToEUR(this.infos.BoxOffice);
        });
    },
    async disableSerie(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/disableSerie/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          location.reload();
        });
    },
    async sortSeries() {
      let select = document.getElementById("triage");
      let value = select.value;
      if (!value) return this.getAll();
      if (value == "random") return this.getAll();
      const [sortBy, sortOrder] = value.split(".");
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const seriePromise = await fetch(
        this.link + "/getSeriesSorted/" + sortBy + "/" + sortOrder,
        requestOptions
      ).then((res) => res.json());
      const series = await seriePromise;
      series.forEach((element) => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1])
          element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(" & ");
        this.series.push(element);
      });
    },
    updateScrollDistance() {
      // Mettez à jour scrollDistance avec la distance entre le haut de la page et la position actuelle de l'utilisateur
      this.scrollDistance = window.scrollY;
    },
    search: async function search() {
      let input = document.getElementById("searchBar").value;
      this.getFromSearch(input);
    },
    resetLoading: async function resetLoading() {
      this.limit = localStorage.getItem("limit");
    },
    loadMore: async function loadMore() {
      this.limit =
        parseInt(this.limit) + parseInt(localStorage.getItem("limit"));
    },
    closeSeenSerie: async function closeSeenSerie() {
      let display = document.getElementById("displayer");
      display.style.display = "none";
      document.body.style.overflow = "auto";
      this.selectedSeries = undefined;
      this.selectedSeason = 1;
      //reset infos
      this.selectedNewEpisodes = undefined;
      this.newSeasons = undefined;
      this.uniqueSeasons = undefined;
      this.infos = undefined;
    },
    randomSerie: async function randomSerie() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getSerieRandom", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.randomFilm = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].categories) {
              let newCat = JSON.parse(data[i].categories);
              if (data[i].categories[0] == data[i].categories[1])
                data[i].categories = data[i].categories.splice(1, 1);
              data[i].categories = newCat.join(" & ");
              this.randomFilm.push(data[i]);
            }
            if (!data[i].categories) return (this.randomFilm = data);
          }
          if (this.randomFilm && this.randomFilm[0])
            this.getSeasonsCount(this.randomFilm[0].id);
        });
    },
    getSeasonsCount: async function getSeasonsCount(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(
        this.link + "/getSeasonsCountFromSerieId/" + id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.randomFilm[0].seasonsCount = data[0].seasonsCount;
        });
    },
    getNewSeasonsFromSerieId: async function getNewSeasonsFromSerieId(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/get/episodes/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.newSeasons = data;
          const seasons = this.newSeasons.map((episode) => episode.season);
          this.uniqueSeasons = [...new Set(seasons)];
          if (this.uniqueSeasons.length > 0)
            this.selectNewEpisodes(this.uniqueSeasons[0]);
        });
    },
    seenSerie: async function seenSerie(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getSerie/" + id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          let display = document.getElementById("displayer");
          display.style.display = "flex";
          document.body.style.overflow = "hidden";
          this.selectedSerie = [];
          data[0].categories = JSON.parse(data[0].categories);
          if (data[0].categories[0] == data[0].categories[1])
            data[0].categories = data[0].categories.splice(1, 1);
          data[0].categories = data[0].categories.join(" & ");
          this.selectedSerie.push(data[0]);
          this.getNewSeasonsFromSerieId(id);
          this.getLastHistoryFromSerie(id);
          this.getOMDBInfos(data[0].imdb_id);
        });
    },
    getLastHistoryFromSerie: async function getLastHistoryFromSerie(serie_id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(
        this.link +
        "/getLastHistoryFromSerie/" +
        localStorage.getItem("userId") +
        "/" +
        serie_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          if (data && data.length > 0) {
            this.lastEpisodeMessage =
              "Dernier épisode regardé : Saison " +
              data[0].season +
              " episode " +
              data[0].episode;
          } else {
            this.lastEpisodeMessage = "";
          }
        });
    },
    selectSeason: function selectSeason() {
      const select = document.getElementById("seasonSelector");
      this.selectedSeason = select.value;
      this.selectNewEpisodes(select.value);
    },
    selectNewEpisodes: function selectNewEpisodes(season) {
      this.selectedNewEpisodes = this.newSeasons.filter(
        (episode) => episode.season == season
      );
    },
    goTop: async function goTop() {
      let anchor = document.getElementById("top");
      let top = anchor.offsetTop;
      window.scrollTo(0, top - 100);
    },
    playSerie: async function playSerie(link, episode, id, season) {
      this.addToHistorical(id, episode, false);
      //window.open(link, '_blank');
      this.$router.push({
        name: "player",
        params: { type: "serie", index: episode - 1, season: season, id: id },
      });
      this.seenSerie(id);
    },
    addToHistorical: async function addToHistorical(id, episode, isMovie) {
      let movieVerification = 0;
      if (isMovie) movieVerification = 1;
      const date = new Date();
      let time = date.toLocaleString("fr-FR");
      let newTime = JSON.stringify(time);
      newTime = newTime.replace(/\//g, "-");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          movieID: id,
          time: newTime,
          userID: localStorage.getItem("userId"),
          isMovie: movieVerification,
          episode: episode,
          season: this.selectedSeason,
        }),
      };
      await fetch(this.link + "/addHistorySerie", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) return console.log(data.error);
        });
    },
    request: async function request(id) {
      localStorage.setItem("request", id);
      this.$router.push("/request");
    },
    deleteSerie: async function deleteMovie(value) {
      let text =
        "Etes vous sur de vouloir supprimer cette série ? Cette action est irréversible, les saisons associées seront elles-aussi supprimées.";
      if (confirm(text) == false)
        return alert("Vous avez annulé la suppresion du film.");
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteSerie/" + value, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          location.reload();
        });
    },
    editSerie: async function editSerie(value) {
      this.$router.push({
        name: "edit",
        params: {
          type: "serie",
          id: value,
        },
      });
    },
    getAll: async function getAll() {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const seriePromise = await fetch(
          this.link + "/allSeries",
          requestOptions
        ).then((res) => res.json());
        const series = await seriePromise;
        series.forEach((element) => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1])
            element.categories = element.categories.splice(1, 1);
          element.categories = element.categories.join(" & ");
          this.series.push(element);
        });
      } catch (error) {
        console.error(error);
      }
    },
    getAllByCategory: async function getAllByCategory() {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      let category = document.getElementById("categories").value;
      if (!category) return this.getAll();
      const seriePromise = await fetch(
        this.link + "/allSeriesFromCategory/" + category,
        requestOptions
      ).then((res) => res.json());
      const series = await seriePromise;
      series.forEach((element) => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1])
          element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(" & ");
        this.series.push(element);
      });
    },
    getCategories: async function getCategories() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getCategories", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.categories = data;
        });
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      await fetch(
        this.link + "/getAdmin/" + localStorage.getItem("userId"),
        requestOptions
      )
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        });
    },
    disconnect: async function disconnect() {
      localStorage.clear();
      this.$router.push("/login");
    },
    getFromSearch: async function getFromSearch(name) {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const seriePromise = await fetch(
        this.link + "/getSerieSearch/" + name,
        requestOptions
      ).then((res) => res.json());
      const series = await seriePromise;
      series.forEach((element) => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1])
          element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(" & ");
        this.series.push(element);
      });
    },
  },
  created: function () {
    this.admin();
    this.getWatchedEpisodes();
    this.getCategories();
    this.getAll();
    this.randomSerie();
  },
  mounted: function () {
    // Ajoutez un écouteur d'événement de défilement pour mettre à jour scrollDistance
    window.addEventListener("scroll", this.updateScrollDistance);
    AOS.init();
    document.body.overflow = "auto";
    var searchBar = document.getElementById("searchBar");
    const selectElement = document.querySelector("#triage");
    searchBar.addEventListener("change", () => {
      if (searchBar.value) return this.getFromSearch(searchBar.value);
      this.getAll();
    });
    selectElement.addEventListener("change", () => {
      if (selectElement.value == -1) return this.getAll();
      if (selectElement.value == -2) return this.getAllFromZ();
      if (selectElement.value == -3) return this.getAllFromLong();
      if (selectElement.value == -4) return this.getAllFromShort();
      if (selectElement.value == -5) return this.getAllRandom();
      if (selectElement.value == -6) return this.getAllFromRecent();
      if (selectElement.value == -7) return this.getAllFromOld();
      if (selectElement.value == -8) return this.getAllFromAnciens();
      if (selectElement.value == -9) return this.getAllFromYoung();
    });
  },
};
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #e60036;
$soft-grey: #2d2e32;

@keyframes slide {
  from {
    opacity: 0;
    transform: translateZ(-30px);
  }

  to {
    opacity: 1;
    transform: translateZ(0px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes slideHorizontal {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.homePage {

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $dark-red rgba(255, 255, 255, 0.178);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.178);
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-red;
    border-radius: 5px;
    border: 0px none #ffffff;
  }

  width: 100%;
  min-height: 100vh;
  background: $dark-color;

  .noResult {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-family: "Lato";
      font-display: swap;
    }
  }

  .watchedSection {
    width: 80%;
    margin: 0 auto;

    .watchedSlider {
      width: auto;

      .watchedCard {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        .delete {
          position: absolute;
          top: 10px;
          right: 10px;
          color: white;
          font-size: 20px;
          transition-duration: 0.5s;

          &:hover {
            cursor: pointer;
            color: $dark-red;
            transition-duration: 0.5s;
          }
        }

        img {
          width: 400px;
          height: 250px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
        }

        .watchingTime {
          position: absolute;
          top: 0;
          left: 0;
          height: 5px;
          background: $dark-red;
          z-index: 3;
        }

        .watchedInfos {
          width: 100%;
          background: #13151de8;
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          gap: 10px;
          min-height: 50px;

          .name {
            //ellipsis
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            color: white;
            font-family: "Lato";
            font-size: 16px;
          }

          button {
            padding: 10px;
            border: none;
            color: white;
            font-family: "Lato";
            font-size: 16px;
            border-radius: 5px;
            transition-duration: 0.5s;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              transition-duration: 0.5s;
            }
          }
        }
      }
    }
  }

  .loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0px;

      button {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        border-radius: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }

        i {
          color: $dark-red;
        }
      }
    }
  }

  .noFilmContainer {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;

    p {
      max-width: 80%;
      color: white;
      font-family: "Montserrat";
    }
  }

  .random {
    width: 100%;
    height: 93vh;
    overflow: none;

    .randomContainer {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        &:first-child {
          width: 100%;
          height: 100%;
          filter: brightness(30%) blur(1px);
          z-index: 0;
          //animation: appear 2s;
          object-fit: cover;
          object-position: top;
        }
      }

      .titleSeasons {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        color: white;
        z-index: 2;

        p {
          font-size: 22px;
          padding: 5px 10px;
          font-family: "Montserrat";
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-family: "Lato";
            font-size: 17px;
            padding: 5px 10px;
          }
        }
      }

      .poster {
        position: absolute;
        bottom: 10%;
        left: 15%;
        z-index: 1;
        color: $light-color;
        display: flex;
        flex-direction: column;
        gap: 5px;

        img {
          width: 330px;
          aspect-ratio: 9/13;
          object-fit: cover;
          filter: blur(0px) brightness(100%);
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
          transition-duration: 0.4s;

          &:hover {
            transition-duration: 0.4s;
            cursor: pointer;
            filter: brightness(70%);
          }
        }

        h4 {
          font-family: "Lato";
          font-weight: bold;
          font-size: 20px;
          padding: 5px;
        }

        .randomCategory {
          font-family: "Montserrat";
          font-size: 16px;
          width: fit-content;
          background: $dark-red;
          padding: 5px;
          border-radius: 5px;
        }

        .randomDuration {
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 16px;
          padding: 5px;
        }

        .randomDescription {
          font-family: "Montserrat";
          font-size: 16px;
          overflow-y: auto;
          max-height: 100px;
          width: 90%;
          padding-right: 20px;
        }

        .posterInteraction {
          padding: 5px 0 5px 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .buttons {
            border: none;
            padding: 5px;
            font-size: 22px;
            color: $light-color;
            background: transparent;
            transition-duration: 0.5s;
            border-bottom: 2px solid transparent;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              border-bottom: 2px solid $dark-red;
              transition-duration: 0.5s;
            }
          }

          .favorite {
            color: $dark-red;
            font-size: 25px;
          }

          .player {
            border-bottom: 2px solid $dark-red;
          }
        }
      }
    }
  }

  .searchBarContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;

    .searchBar {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light-color;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 20px;

      img {
        width: 20px;
        height: 20px;
        order: 1;

        &:hover {
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        outline: none;
        padding: 5px 10px;
        background: transparent;
        border: none;
        border-right: 1px solid $dark-color;
      }
    }
  }

  .displayer {
    width: 100%;
    height: 100vh;
    background: rgba(20, 22, 28, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    display: none;
    justify-content: center;
    align-items: center;

    //animation: slideHorizontal 1s;
    .displayerContainer {
      width: 60%;
      height: 100%;
      background: $back-color;
      color: white;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      h3 {
        margin: 10px 0;
      }

      a {
        font-family: "Montserrat";
      }

      p {
        margin-top: 10px;
        font-family: "Montserrat";

        &:first-child {
          color: white;
        }

        &:last-child {
          height: 200px;
        }

        color: rgba(255, 255, 255, 0.61);
      }

      ul {
        height: fit-content;
        overflow: auto;
        font-family: "Montserrat";
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          button {
            width: max-content;
            min-width: 130px;
            border: none;
            background: $light-color;
            color: $dark-color;
            font-size: 16px;
            padding: 10px 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .cat {
        color: $light-color;
        font-family: "Lato";
        width: 100%;
        margin: 10px 0;

        p {
          width: fit-content;
          height: auto;
          background: $dark-red;
          font-size: 15px;
          padding: 5px 10px;
          border-radius: 5px;
          font-weight: bold;
        }
      }

      .exit {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
        background-color: transparent;

        button {
          border: none;
          background: transparent;
          color: $light-color;
          font-size: 30px;

          &:hover {
            cursor: pointer;
            animation: spin 2s forwards;
          }

          i {
            color: white;
            filter: brightness(200%);
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);
          }
        }
      }

      .upper {
        width: 100%;
        height: 60vh;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        gap: 10px;

        img {
          &:first-child {
            z-index: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            filter: brightness(40%);
            object-fit: cover;
            animation: appear 2s;
          }

          &:nth-child(2) {
            z-index: 1;
            width: 250px;
            aspect-ratio: 9/13;
            object-fit: cover;
            margin: 30px;
            box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
            border-radius: 10px;
          }
        }

        .section {
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateY(-15px);
          margin-right: 20px;

          button {
            margin: 0 10px;
            border: none;
            background: transparent;
            color: $light-color;
            font-size: 32px;
            transition-duration: 1s;
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);

            &:hover {
              cursor: pointer;
              transition-duration: 1s;
              color: $dark-red;
              filter: drop-shadow(0px 0px 0 transparent) drop-shadow(0px 0px 0 transparent);
            }
          }
        }
      }

      h3 {
        font-family: "Lato";
        font-size: 24px;
      }

      .lower {
        width: 95%;
        padding: 15px;

        .lastEpisode {
          margin-top: 10px;
          padding: 5px 10px;
          background: $dark-red;
          max-width: 100%;
          width: fit-content;
          border-radius: 10px;

          p {
            margin: 0;
            height: auto;
            color: $light-color;
            font-family: "Montserrat";
            font-size: 16px;

            span {
              a {
                color: $light-color;
                font-size: 16px;
              }
            }
          }
        }

        .seasonSelector {
          margin-top: 20px;
          min-width: 200px;
          height: 40px;
          background: $light-color;
          border: none;
          border-radius: 5px;
          padding: 5px;
          font-family: "Montserrat";
          font-size: 16px;
          outline: none;
          color: $dark-color;
        }

        .movieInfosContainer {
          width: 100%;
          max-width: 100%;
          background: $dark-color;
          border-radius: 10px;
          margin: 10px 0 35px 0;

          .movieInfos {
            width: 90%;
            max-width: 90%;
            height: auto;
            padding: 10px 20px;
            gap: 5px;
            display: flex;
            flex-direction: column;

            p {
              margin: 0;
              font-family: "Montserrat";
              font-display: swap;
              font-size: 16px;
              font-weight: bold;
              color: $light-color;
              height: fit-content;

              span {
                font-weight: normal;
                color: rgba(240, 240, 240, 0.863);
              }
            }
          }

          .rating {
            width: 100%;
            height: auto;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            padding-bottom: 30px;

            .ratingCard {
              width: fit-content;
              height: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 33%;

              div {
                img {
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                }
              }

              p {
                padding-bottom: 3px;
                font-family: "Montserrat";
                font-display: swap;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
              }

              span {
                font-family: "Montserrat";
                font-display: swap;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
              }
            }
          }
        }

        .loginContainer {
          margin: 20px 0 0 0;
          background: $soft-grey;
          max-width: 100%;
          padding: 5px 15px;
          border-radius: 15px;

          p {
            font-family: "Montserrat";
            font-size: 18px;
            color: $light-color;
          }

          ul {
            margin-top: 5px;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2px;

            li {
              font-style: "Montserrat";

              b {
                font-size: 18px;
              }
            }
          }
        }

        .episodes {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          padding: 50px 0;

          .episode {
            display: flex;
            justify-content: start;
            align-items: flex-start;
            gap: 15px;
            position: relative;
            width: 90%;
            border-radius: 10px;
            background: $dark-color;
            overflow: hidden;
            box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
            height: 180px;

            .disabled {

              img {
                filter: grayscale(100%);
              }
            }

            .episodePoster {
              position: relative;
              width: 30%;
              height: auto;
              transition-duration: 0.5s;

              img {
                width: 100%;
                height: 180px;
                object-fit: cover;
                object-position: center;
                border-radius: 10px 0 0 10px; // Applique le border-radius à l'image pour les coins arrondis
                display: block; // Évite les espaces blancs sous l'image
                z-index: 1;
              }

              &:hover {
                cursor: pointer;
                transition-duration: 0.5s;
                filter: brightness(70%);
              }

              .watchingTime {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 5px !important;
                background: $dark-red;
                z-index: 5;
              }
            }

            .episodeInfos {
              position: relative;
              width: 70%; // Adapte la largeur de la section infos à celle de l'image
              background: $dark-color;
              display: flex;
              flex-direction: column;

              p {
                font-family: "Montserrat";
                font-size: 15px;
                color: $light-color;
                margin-left: 10px;
                max-width: 80%;

                &:last-child {
                  padding-bottom: 8px;
                  color: rgba(240, 240, 240, 0.5);
                  overflow-y: auto;
                  max-width: 100% !important;
                  width: 98%;
                  height: auto !important;
                  /* Add a fixed height to limit the expansion of the paragraph */
                  max-height: 165px;
                }
              }

              .duration {
                position: absolute;
                top: 5px;
                right: 5px;
                background: transparent;
                padding: 5px;
                border-radius: 5px;
                font-family: "Montserrat";
                font-size: 15px;
                color: white;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .goTop {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: auto;
    aspect-ratio: 1/1;
    z-index: 10;

    button {
      color: white;
      background: $dark-red;
      padding: 5px;
      border: none;
      border-radius: 5px;
      display: block;
      animation: appear 1s forwards;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;

        i {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  .order {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    animation: appear 2s;
    font-family: "Lato";

    select {
      width: 15%;
      border: none;
      background-color: $dark-red;
      border-radius: 5px;
      color: white;
      padding: 10px;
      outline: none;
      text-indent: 1px;
      text-overflow: "";
      margin: 10px;
      -webkit-appearance: none;

      &:hover {
        cursor: pointer;
      }

      option {
        background: white;
        color: $dark-color;
        padding: 5px 10px;
      }
    }

    button {
      margin: 10px;
      padding: 5px 10px;
      border: none;
      animation: appear 2s;
      font-family: "Lato";
      font-size: 16px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      background: $dark-red;
      color: white;
      position: relative;
      transition-duration: 1s;

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition-duration: 1s;
      }
    }
  }

  .filmsSection {
    padding: 60px 0 150px 0;
    width: 90%;
    min-height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .filmsSection {
      width: 95%;

      .card {
        min-width: 25%;
        margin: 20px;

        img {
          width: 100%;
          height: 410px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;

        .lower {
          .episodes {
            .episode {
              flex-direction: column;
              height: auto;
              gap: 0;

              .episodePoster {
                width: 100%;
              }

              .episodeInfos {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .filmsSection {
      width: 100%;

      .card {
        min-width: 290px;
        margin: 20px;

        img {
          width: 100%;
          height: 430px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .watchedSection {
      margin-top: 40px;
      width: 95%;
      .watchedSlider {
        width: 100%;
        .watchedCard {
          img {
            width: 100%;
            height: 200px;
          }
        }
      }
    }

    .random {
      .randomContainer {
        .titleSeasons {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          width: fit-content;
          color: white;
          z-index: 2;
          max-width: 425px;
          width: 100%;

          p {
            font-size: 18px;
            font-weight: bold;
            padding: 5px 10px;
            font-family: "Montserrat";
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-weight: 0;
              font-family: "Lato";
              font-size: 16px;
              padding: 5px 10px;
            }
          }
        }

        .poster {
          bottom: 10%;
          left: 5%;

          img {
            width: 250px;
            filter: brightness(100%);
          }
        }
      }
    }

    .noFilmContainer {
      p {
        max-width: 80%;
        color: white;
        font-family: "Montserrat";
      }
    }

    .displayer {
      width: 100%;
      align-items: flex-start;

      .displayerContainer {
        width: 100%;
        height: 100vh;
        border-radius: 0;

        .lower {
          height: fit-content;
          margin-top: 35px;
          padding: 5px;
          padding-bottom: 50px;
          margin-left: 5px;

          p {
            overflow-y: auto;
            margin-top: 10px;
            font-family: "Montserrat";

            &:first-child {
              color: white;
              height: fit-content;
            }

            &:last-child {
              height: 90%;
              max-height: 90%;
            }

            color: rgba(255, 255, 255, 0.61);

            i {
              margin-right: 3px;
            }
          }

          ul {
            max-height: fit-content;
            height: fit-content;

            li {
              margin: 5px 0;
            }
          }

          .episodes {
            .episode {
              flex-direction: column;
              width: 95%;

              .episodePoster {
                width: 100%;
              }

              .episodeInfos {
                p {
                  font-size: 14px;

                  &:last-child {
                    max-height: 100px;
                    width: 95%;
                  }
                }
              }
            }
          }
        }

        .upper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          height: fit-content;

          img {
            border-radius: 0;

            &:first-child {}

            &:nth-child(2) {}
          }

          .section {
            position: absolute;
            bottom: 0;
            right: 10px;
            z-index: 1;
            transform: translateY(50px);
            margin-right: 5px;

            button {
              width: fit-content;
              margin: 0 6px;

              i {
                font-size: 30px;
              }
            }
          }
        }
      }
    }

    .order {
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 90%;
      }
    }

    .searchBarContainer {
      order: -1;
      width: 100%;

      .searchBar {
        width: 100%;
      }
    }

    .filmsSection {
      width: 100%;

      .card {
        width: 95%;
        max-height: fit-content;
        margin: 20px;

        img {
          height: auto;
        }
      }
    }
  }
}
</style>
