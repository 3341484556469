<template>
    <div v-if="demandes != undefined && demandes.length > 0" class="usersContent">
        <div class="usersTab" v-if="demandes != undefined && demandes.length != 0">
            <Table :columns="columns" :data="demandes" @update="getDemandes()" @sort="sort" />
        </div>
    </div>
    <div v-if="isLoaded && !demandes" class="usersContent">
        <div class="usersTab">
            <h1>Aucune demande trouvée..</h1>
        </div>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

import Table from './DemandesTableComponent.vue';

export default {
    name: "DemandesDashboardComponent",
    components: {
        Table,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            demandes: undefined,
            columns: [
                { field: "userSeen", label: "Vu" },
                { field: "date", label: "Demandé le" },
                { field: "username", label: "Demandeur" },
                { field: "title", label: "Titre" },
                { field: "imdbID", label: "IMDB" },
                { field: "year", label: "Année" },
                { field: "type", label: "Type" },
                { field: "status", label: "Status" },
                { field: "delete", label: "Supprimer" },
                { field: "accept", label: "Accepter" },
                { field: "refuse", label: "Refuser" },
                { field: "clone", label: "Clone" }
            ],
            isAdmin: false,
            isLoaded: false,
        };
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
        };
    },
    methods: {
        sort(order_by, sort_by) {
            let demandesToSort = this.demandes;
            let column = order_by;
            let order = sort_by;
            if (order == null) return this.getDemandes();
            demandesToSort.sort((a, b) => {
                if (order === 'ASC') {
                    if (a[column] < b[column]) return -1;
                    if (a[column] > b[column]) return 1;
                    return 0;
                } else if (order === 'DESC') {
                    if (a[column] > b[column]) return -1;
                    if (a[column] < b[column]) return 1;
                    return 0;
                }
            });
        },
        factoryDemandes(demandes) {
            demandes.forEach((demande) => {
                demande.userSeen = demande.userSeen ? true : false;
                switch (demande.type) {
                    case 'movie':
                        demande.type = 'Film';
                        break;
                    case 'series':
                        demande.type = 'Série';
                        break;
                    default:
                        demande.type = 'Film';
                        break;
                }
                demande.imdbID = demande.imdbID ? demande.imdbID : '..';
                demande.status = demande.status && demande.status.length > 0 ? demande.status : 'En attente';
            });
            this.demandes = demandes;
            this.$emit("loading-ended");
            this.isLoaded = true;
        },
        async getDemandes() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/getAllDemandes", requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.length > 0) return this.factoryDemandes(data);
                    this.$emit("loading-ended");
                    this.isLoaded = true;
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
                    this.isAdmin = true;
                });
        },
    },
    watch: {
        '$route': 'onRouteChange',
    },
    created: function () {
        this.admin();
        this.getDemandes();
    },
    mounted: function () {

    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.usersContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    .usersTab {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;

        h1 {
            color: rgba(255, 255, 255, 0.74);
            font-size: 1.5em;
            font-weight: 400;
            font-family: 'Lato';
        }
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 480px) {}
</style>